import { Box, Divider, Typography } from '@mui/material';
import { labels, profileMsg } from '../../data/constants';
import ConstructionIcon from '@mui/icons-material/Construction';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { NavLink, useNavigate } from 'react-router-dom';
import ProPlanContainer from './ProPlanContainer';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { useSelector } from 'react-redux';

const ProComp = () => {
  const isProAccount = useSelector((state) => state?.loginUserData?.user?.userDetails?.proAccountDetails?.isProAccount);

  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <Typography component={'h1'} variant="dashboardCompHeading">
        {labels.pro.title}
      </Typography>
      {isProAccount
        ?
        <Box className="displayFlex alignItemsStart mt1_5 tipBackgroundSuccess tipColorSuccess borderRadius4 padding12x16">
          <CheckCircleOutlineIcon fontSize="small" />
          <Box className="ml1">
            <Typography className="fontSize14 fontWeight700 mb1">
              {labels.pro.activePlanLabel}
            </Typography>
            <Typography className="colorBlack fontSize14">
              {labels.pro.activePlanMsg1}
              {' '}
              {/* bring dynamic plan name from BE */}
              <b>Pro Annual</b>
              {' '}
              {labels.pro.activePlanMsg2}
              {' '}
              <NavLink to={'/account?tab=Plan'}>
                {labels.pro.activePlanAccountSettings}
              </NavLink>
            </Typography>
          </Box>
        </Box>
        :
        <Box className="displayFlex alignItemsStart mt1_5 tipBackgroundSuccess tipColorSuccess borderRadius4 padding12x16">
          <TipsAndUpdatesOutlinedIcon fontSize="small" />
          <Box className="ml1">
            <Typography className="fontSize14 fontWeight700 mb1">
              {labels.nonPro.noCommit}
            </Typography>
            <Typography className="colorBlack fontSize14">
              {labels.nonPro.noCommitMsg}
            </Typography>
          </Box>
        </Box>
      }
      <ProPlanContainer />
      <Box className="displayFlex alignItemsStart mt1 tipBackgroundWarning padding12x16 borderRadius4 tipColorWarning">
        <TipsAndUpdatesOutlinedIcon fontSize="small" />
        <Box className="ml1">
          <Typography className="fontSize14 fontWeight700 mb1">
            {labels.pro.questions}
          </Typography>
          <Typography className="colorBlack fontSize14">
            <b>{labels.pro.qMsg1}</b>
            {' '}
            {/* bring dynamic plan name from BE */}
            {labels.pro.qMsg2}<br /><br />
            {labels.pro.qMs3}
            {' '}
            <NavLink to='/'>
              {labels.pro.qMsg4}
            </NavLink>
            {' '}
            {labels.pro.qMsg5}
            {' '}
            <a href={`mailto:${labels.supportEmailID}`}>
              {labels.supportEmailID}
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProComp;
