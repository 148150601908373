import { Box, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { labels } from '../../data/constants';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from "@mui/material/styles";
import { addComment, listComments } from '../../Services/commentService';
import { saved, saving } from '../../redux/saveSlice';
import CommentsHistory from './CommentsHistory';
import { useLocation } from 'react-router-dom';

const CommentsTabContent = ({ commentsHistory }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [associationWithCurrentPage, setAssociationWithCurrentPage] = useState(false);
  const [fetchedComments, setFetchedComments] = useState(commentsHistory);
  const dispatch = useDispatch();
  const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  }));

  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );

  const location = useLocation();

  const handleSubmit = async () => {
    if (fieldValue !== '') {
      let payload = {
        comment: fieldValue,
        workspaceId: userWorkspace.id,
        ...(associationWithCurrentPage && { currentpageassociation: location.pathname })
      };
      dispatch(saving());
      const response = await addComment(payload, dispatch);
      if (response) {
        dispatch(saved());
        const tempPayload = {
          workspaceId: userWorkspace.id,
        };
        const tempResponse = await listComments(tempPayload, dispatch);
        setFetchedComments(tempResponse);
      }
      setFieldValue('');
    }
  };

  return (
    <>
      <Box className='padding1'>
        <TextField
          className="textFieldType4 mt05 backgroundWhite"
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          fullWidth
          placeholder={labels.comments.commentsTab.addCommentPlaceholder}
        />
        <Button
          className="textTrasformCapital"
          variant="buttonVariant1"
          onClick={handleSubmit}>
          {labels.comments.commentsTab.addCommentButtonLabel}
        </Button>
        <FormControlLabel
          className="mx0px"
          control={
            <SmallCheckbox
              checked={associationWithCurrentPage}
              onChange={(e) => setAssociationWithCurrentPage(e.target.checked)}
            />
          }
          label={
            <span className="fontSize12 displayBlock mx0px">
              {labels.comments.commentsTab.associationCheckboxText}
            </span>
          }
        />
        <CommentsHistory commentsHistory={fetchedComments} />
      </Box>
    </>
  );
};

export default CommentsTabContent;
