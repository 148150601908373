import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useEffect, useState } from 'react';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { labels } from '../../../data/constants';
import MarketSubCategoriesPanel from './SubCategory/MarketSubCategoriesPanel';
import { useSelector } from 'react-redux';
import { getMarketSubCategoriesService } from '../../../Services/ResearchServices';
import ResearchDeleteModal from '../ResearchDeleteModal';
const MarketInfoBlock = ({
  marketData = null,
  updateMarketDetail = () => {},
  handleAddSubCategory = () => {},
  marketDeleteHandler = () => {},
}) => {
  const [marketDetail, setMarketDetail] = useState(null);
  const [marketSubCategories, setMarketSubCategories] = useState([]);
  const [marketDetailCopy, setMarketDetailCopy] = useState(null);
  const [nameEdit, setNameEdit] = useState(false);
  const [showMarketDeleteModal, setShowMarketDeleteModal] = useState(false);
  const [disableState, setDisableState] = useState(false);
  const [nameError, setNameError] = useState({
    status: false,
    msg: '',
  });
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );

  const fetchMarketSubCategories = async () => {
    try {
      let payload = {
        marketId: +marketData?.id,
        workspaceId: +userWorkspace?.id,
      };
      setMarketSubCategories([]);
      const response = await getMarketSubCategoriesService(payload);
      if (response) {
        let marketDataStructure = [];
        response?.forEach((subCategory) => {
          marketDataStructure.push({
            subCategoryName: subCategory?.sub_category_name,
            isNewSubCategory: subCategory?.new_sub_category,
            keyMarket: subCategory?.key_market_challenge,
            keyBarrier: subCategory?.key_barriers_to_entry,
            id: subCategory?.id,
            marketId: subCategory?.market_id,
            strengthWeakness: [...subCategory?.strengthWeaknesses],
          });
        });
        setMarketSubCategories(marketDataStructure);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (marketData) {
      fetchMarketSubCategories();
      setMarketDetail(marketData);
      setMarketDetailCopy(marketData);
    }
  }, [marketData]);
  const updateMarketName = async () => {
    try {
      if (marketDetailCopy?.market_name === marketDetail?.market_name) {
        setNameEdit(false);
        return;
      }
      if (!marketDetailCopy?.market_name) {
        setNameError({
          status: true,
          msg: 'Name cannot be blank!',
        });
        return;
      }
      const response = updateMarketDetail(marketDetailCopy);
      if (response) {
        setNameEdit(false);
        setMarketDetail((prevState) => {
          return {
            ...prevState,
            market_name: marketDetailCopy?.market_name,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateDetails = (fieldName) => {
    if (marketDetailCopy[fieldName] === marketDetail[fieldName]) {
      return;
    }
    updateMarketDetail(marketDetailCopy);
  };
  const submitCategoryRequest = async (subCategoryName, isNewSubCategory) => {
    const response = await handleAddSubCategory(
      subCategoryName,
      isNewSubCategory
    );
    if (response) {
      fetchMarketSubCategories();
      return true;
    }
  };
  const handleMarketDelete = async () => {
    setDisableState(true);
    const response = await marketDeleteHandler(
      marketData?.id,
      userWorkspace?.id
    );
    if (response) {
      setShowMarketDeleteModal(false);
    }
  };
  return (
    <Box className="ml2">
      {!nameEdit ? (
        <Box className="displayFlex alignItemsCenter">
          <Typography
            component={'h4'}
            variant="dashboardCompHeading"
            className="fontSize18 textTrasformCapital mr1"
          >
            {marketDetailCopy?.market_name}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setNameEdit(true);
            }}
          >
            <CreateOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowMarketDeleteModal(true);
            }}
            aria-label="delete"
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <Box className="displayFlex alignItemsCenter justifySpaceBetween">
            <TextField
              className="textFieldType4 mr1 mt0 mb0"
              value={marketDetailCopy?.market_name}
              onChange={(e) => {
                setMarketDetailCopy((prevState) => {
                  return { ...prevState, market_name: e.target.value };
                });
                setNameError({ status: false, msg: '' });
              }}
            />
            <Button
              className="textAlignCenter textTransformNone displayFlex alignItemsCenter"
              variant="buttonVariant1"
              startIcon={<DoneOutlinedIcon fontSize="small" />}
              onClick={updateMarketName}
            >
              Done
            </Button>
          </Box>
          <FormHelperText className="mt05" error={nameError.status}>
            {nameError.msg}
          </FormHelperText>
        </Box>
      )}
      <Box className="mt1">
        <Grid container columnSpacing={2}>
          <Grid item lg={2}>
            <InputLabel
              sx={{
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">TAM (millions)</Typography>
              <Tooltip
                title={
                  'Total Addressable Market: the total amount of money that could be made if a product or service was sold to everyone who could possibly want it.'
                }
              >
                <InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 1 }} />
              </Tooltip>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4"
              type="number"
              value={marketDetailCopy?.tam || ''}
              onChange={(e) => {
                setMarketDetailCopy((prevState) => {
                  return {
                    ...prevState,
                    tam: e.target.value,
                  };
                });
              }}
              onBlur={() => {
                updateDetails('tam');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment className="ml05" position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={10}>
            <InputLabel
              sx={{ overflow: 'visible' }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">Source</Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4"
              placeholder="TAM Source URL"
              value={marketDetailCopy?.tam_url || ''}
              onChange={(e) => {
                setMarketDetailCopy((prevState) => {
                  return {
                    ...prevState,
                    tam_url: e.target.value,
                  };
                });
              }}
              onBlur={() => {
                updateDetails('tam_url');
              }}
            />
          </Grid>
          <Grid item lg={2} className="mt1">
            <InputLabel
              sx={{
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">CAGR %</Typography>
              <Tooltip
                title={
                  'Compound Annual Growth Rate Percent: the predicted average yearly growth rate of the market over the next few years, as a percent of the market size, considering the effect of compounding'
                }
              >
                <InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 1 }} />
              </Tooltip>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4"
              type="number"
              value={marketDetailCopy?.cagr || ''}
              onChange={(e) => {
                setMarketDetailCopy((prevState) => {
                  return {
                    ...prevState,
                    cagr: e.target.value,
                  };
                });
              }}
              onBlur={() => {
                updateDetails('cagr');
              }}
            />
          </Grid>
          <Grid item lg={10} className="mt1">
            <InputLabel
              sx={{ overflow: 'visible' }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">Source</Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4"
              placeholder="CAGR Source URL"
              value={marketDetailCopy?.cagr_url || ''}
              onChange={(e) => {
                setMarketDetailCopy((prevState) => {
                  return {
                    ...prevState,
                    cagr_url: e.target.value,
                  };
                });
              }}
              onBlur={() => {
                updateDetails('cagr_url');
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {marketSubCategories.length !== 0 && (
        <Typography
          component={'h4'}
          variant="dashboardCompHeading"
          className="fontSize18 textTrasformCapital mt1"
        >
          {labels.research.marketTab.subCategoriesLabel}
        </Typography>
      )}
      <MarketSubCategoriesPanel
        marketSubCategories={marketSubCategories.sort((a, b) => a?.id - b?.id)}
        handleSubmit={submitCategoryRequest}
        fetchSubCategories={fetchMarketSubCategories}
      />
      {showMarketDeleteModal && (
        <ResearchDeleteModal
          title={labels.research.marketTab.marketDeleteModal.heading}
          message1={labels.research.marketTab.marketDeleteModal.message1}
          message2={labels.research.marketTab.marketDeleteModal.message2}
          isDisabled={disableState}
          confirmDeleteMarket={handleMarketDelete}
          handleClose={() => {
            setShowMarketDeleteModal(false);
          }}
        />
      )}
    </Box>
  );
};
export default MarketInfoBlock;
