import axios from 'axios';
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300, STATUS_401, STATUS_403 } from "../data/constants";
import { saved, saving, couldnotSave } from '../redux/saveSlice';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const addComment = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.addComment}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    // showErrorMessage(e);
    return false;
  }
};

export const listComments = async (payload, dispatch) => {
  try {
    const resp = await axios.post(`${ApiConfig.listComments}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data?.data;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    // showErrorMessage(e);
    return false;
  }
}

export const addReply = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.addReply}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    // showErrorMessage(e);
    dispatch(couldnotSave());
    return false;
  }
}

export const editComment = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.editComment}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    // showErrorMessage(e);
    dispatch(couldnotSave());
    return false;
  }
}

export const deleteComment = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteComment}`, { data: payload });
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return true;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    // showErrorMessage(e);
    dispatch(couldnotSave());
    return false;
  }
}

export const listChanges = async (payload, dispatch) => {
  try {
    const resp = await axios.post(`${ApiConfig.listChanges}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data?.data;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    // showErrorMessage(e);
    return false;
  }
}

export const logChanges = async (payload, dispatch) => {
  try {
    const resp = await axios.post(`${ApiConfig.logChange}`, payload);
    if (resp?.data.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      dispatch(saved());
      return resp?.data?.data;
    } else {
      dispatch(couldnotSave());
      throw resp;
    }
  } catch (e) {
    dispatch(couldnotSave());
    // showErrorMessage(e);
    return false;
  }
}
