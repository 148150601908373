import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import {
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  FormHelperText,
} from '@mui/material';
import { labels } from '../../data/constants';
import { useState } from 'react';
const AddAssessmentModal = ({
  handleCancel = () => {},
  submitDetails = () => {},
}) => {
  const handleSubmit = () => {
    if (fieldValue === '') {
      setFieldError({
        status: true,
        message: 'Name cannot be blank!',
      });
      return;
    }
    submitDetails(fieldValue);
  };
  const [fieldValue, setFieldValue] = useState('');
  const [fieldError, setFieldError] = useState({ status: false, message: ' ' });
  return (
    <CustomDialog
      handleOpen={true}
      showClose={false}
      width={'xs'}
      dialogTitle="Add Competitor Assessment Category"
      headingVariant="modalHeading1"
    >
      <InputLabel
        shrink={false}
        required
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.addAssessmentModal.fieldLabel}
        </Typography>
      </InputLabel>
      <TextField
        className="textFieldType4 mt05 backgroundWhite"
        value={fieldValue}
        onChange={(e) => {
          setFieldValue(e.target.value);
          setFieldError({ status: false, msg: '' });
        }}
        fullWidth
        placeholder={labels.research.addAssessmentModal.placeholder}
      />
      <FormHelperText className="mt05" error={fieldError.status}>
        {fieldError.message}
      </FormHelperText>
      <Box className="justifySpaceBetween displayFlex mt1_5">
        <Button variant="buttonVariant1" onClick={handleCancel}>
          {labels.commonLabels.cancelLabel}
        </Button>
        <Button variant="buttonVariant1" onClick={handleSubmit}>
          {labels.commonLabels.submitLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};
export default AddAssessmentModal;
