import { Grid, useTheme, useMediaQuery } from '@mui/material';
import Header from '../../Components/MainLayout/Header/header';
import Footer from '../../Components/MainLayout/Footer/footer';
import Sidebar from '../../Components/Dashboard/Sidebar/Sidebar';
import { useSelector } from 'react-redux';
const DashboardLayout = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userDetails = useSelector(
    (state) => state?.loginUserData?.user?.userDetails
  );
  return (
    <Grid container>
      <Grid className='mb2' item xs={12} sm={12}>
        <Header isLoggedIn={userDetails} />
        <Grid container>
          {!isMobile ? (
            <Grid item md={2}>
              <Sidebar />
            </Grid>
          ) : (
            ''
          )}
          <Grid className='paddingTop80' item md={!isMobile ? 10 : 12}>
            {props.children}
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default DashboardLayout;
