import { Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { labels } from '../../data/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommentsTabs from './CommentsTabs';
import CommentsTabContent from './CommentsTabContent';
import ChangesTabContent from './ChangesTabContent';

const Comments = ({ onClose, commentsHistory, changesHistory }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleManageCollaborators = () => {
    navigate('/workspace');
  };

  return (
    <>
      <Box className='displayFlex justifySpaceBetween alignItemsCenter'>
        <Typography
          component="h4"
          variant="dashboardCompHeading"
          className="fontSize18 textTransformCapital mr1"
        >
          {labels.comments.title}
        </Typography>
        <IconButton onClick={onClose} style={{ marginLeft: '8px' }}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
      <Box className='borderBottom2 borderColorGrey'>
        <CommentsTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </Box>
      <Box style={{ maxHeight: '430px', minHeight: '430px', overflowY: 'auto' }}>
        {selectedTab === 0 && (
          <CommentsTabContent commentsHistory={commentsHistory} />
        )}
        {selectedTab === 1 && (
          <ChangesTabContent changesHistory={changesHistory} />
        )}
      </Box>
      <Box className='justifySpaceBetween displayFlex paddingTop10'>
        <Button className='textTrasformCapital' variant='purpleBtnLight' onClick={handleManageCollaborators}>
          {labels.manageCollabButton}
        </Button>
        <Button className='textTrasformCapital' variant='buttonVariant1' onClick={onClose}>
          Close
        </Button>
      </Box>
    </>
  );
};

export default Comments;
