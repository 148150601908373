import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { labels } from '../../../data/constants';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import MarketInfoBlock from './MarketInfoBlock';
import {
  createMarketSubCategoryService,
  deleteMarketService,
} from '../../../Services/ResearchServices';
import { useSelector, useDispatch } from 'react-redux';
import { saved, saving, couldnotSave } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';

const MarketTabContent = ({
  marketList = [],
  handleAddMarket = () => {},
  fetchMarketList = () => {},
  updateMarket = () => {},
  fetchMarketWithSubCats = () => {},
}) => {
  const dispatch = useDispatch();
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  const [showAddMarketModal, setShowAddMarketModal] = useState(false);
  const [activeMarket, setActiveMarket] = useState(null);

  useEffect(() => {
    if (selectedMarketOption && marketList.length) {
      let filteredMarket = marketList.filter(
        (marketOption) => marketOption?.id === selectedMarketOption?.market_id
      );
      setActiveMarket(filteredMarket[0]);
    }
  }, [selectedMarketOption]);
  const addMarket = async (marketName) => {
    const status = await handleAddMarket(marketName);
    if (status) {
      setShowAddMarketModal(false);
    }
  };

  const addMarketSubCategory = async (subCategoryName, isNewSubCategory) => {
    try {
      let payload = {
        marketId: activeMarket?.id,
        subCategoryName: subCategoryName,
        newSubCategory: isNewSubCategory,
      };
      dispatch(saving());
      const response = await createMarketSubCategoryService(payload, dispatch);
      if (response) {
        fetchMarketWithSubCats();
        dispatch(saved());
        let changeLogPayload = {
          changeDetail: `Market Sub-Category '${subCategoryName}' created`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  const deleteMarket = async (marketId, workspaceId) => {
    try {
      let payload = {
        workspaceId: workspaceId,
        marketId: marketId,
      };
      dispatch(saving());
      const response = await deleteMarketService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setActiveMarket(null);
        fetchMarketList();
        fetchMarketWithSubCats();
        let changeLogPayload = {
          changeDetail: `Market '${activeMarket?.market_name}' deleted`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  return (
    <Box>
      <Typography
        component={'h3'}
        variant="dashboardCompHeading"
        className="fontSize26 mt1"
      >
        {labels.research.marketTab.heading}
      </Typography>
      {marketList?.length && marketList?.length !== 0 ? (
        <Grid container className="mt1">
          <Grid item lg={2}>
            <Stack
              className="backgroundLightGrey borderRadius8 padding12x16"
              spacing={1}
              divider={<Divider />}
            >
              {marketList?.map((marketOption, index) => (
                <Button
                  key={'marketOption' + index}
                  className={
                    marketOption?.id === activeMarket?.id ? 'active' : ''
                  }
                  variant="stackButton"
                  fullWidth
                  onClick={() => {
                    setActiveMarket(marketOption);
                  }}
                >
                  {marketOption?.market_name}
                </Button>
              ))}
            </Stack>
            <Button
              className="mt1 textTrasformCapital"
              variant="buttonVariant1"
              onClick={() => {
                setShowAddMarketModal(true);
              }}
            >
              {labels.research.marketTab.addMarketBtnLabel}
            </Button>
          </Grid>
          <Grid item lg={10}>
            {activeMarket ? (
              <MarketInfoBlock
                updateMarketDetail={updateMarket}
                marketData={activeMarket}
                handleAddSubCategory={addMarketSubCategory}
                marketDeleteHandler={deleteMarket}
              />
            ) : (
              <Typography
                component={'h2'}
                fontSize={24}
                fontWeight={'bold'}
                className="ml2"
              >
                Please select a market.
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box className="textAlignCenter">
          <Typography
            component={'h2'}
            mt={5}
            fontSize={24}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            {labels.research.marketTab.noMarketMsg}
          </Typography>
          <Button
            className="mt1 textTrasformCapital"
            variant="buttonVariant1"
            onClick={() => {
              setShowAddMarketModal(true);
            }}
          >
            {labels.research.marketTab.addMarketBtnLabel}
          </Button>
        </Box>
      )}
      {showAddMarketModal && (
        <AddMarketOrCompetitorModal
          dialogTitle={labels.research.addMarketModal.title}
          toAdd={'Market'}
          handleClose={() => {
            setShowAddMarketModal(false);
          }}
          submitMarketDetail={addMarket}
        />
      )}
    </Box>
  );
};
export default MarketTabContent;
