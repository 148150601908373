import {
  Box,
  Grid,
  Typography,
  InputLabel,
  TextField,
  Button,
  Tabs,
  Tab,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomRadioGroup from '../../Components/Custom Components/RadioGroup/RadioGroup';
import CustomDialog from '../../Components/Custom Components/CustomDialog/CustomDialog';
import GradingIcon from '@mui/icons-material/Grading';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../redux/login';
import {
  GetRolesList,
  UpdateEmail,
  UpdateRole,
  getLocationListService,
  getUserDetailService,
  updateUserDetailsService,
  verifyEmailChngOtpService,
} from '../../Services/AccountServices';
import { useAuth } from '../../Context/AuthProvider';
import {
  errors,
  labels,
  limitations,
  placeholders,
  regexes,
  warnings,
} from '../../data/constants';
import AccountOtpModal from './AccountOtpModal';
import SuccessModal from './SuccessModal';
import { clearStorage } from '../../storage/Storage';
import AccountSkeleton from '../../Components/Accounts/AccountsSkeleton';
const AccountComponent = () => {
  const userDetailStore = useSelector(
    (state) => state?.loginUserData?.user?.userDetails
  );
  const userPlanDetailStore = useSelector(
    (state) => state?.loginUserData?.user?.planDetails
  );
  const navigate = useNavigate();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [emailStatus, setEmailStatus] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [userEmailCopy, setUserEmailCopy] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleOption, setSelectedRoleOption] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [originalSelectedCountry, setOriginalSelectedCountry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [originalFirstName, setOriginalFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [originalLastName, setOriginalLastName] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [showSaveEmailModal, setshowSaveEmailModal] = useState(false);
  const [disableEmailChngBtns, setDisableEmailChngBtns] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showRoleChangeModal, setShowRoleChangeModal] = useState(false);
  const [planOptions, setPlanOptions] = useState([
    {
      label: 'Pro Annual ',
      value: 'proAnnual',
    },
    {
      label: 'Pro Unlimited ',
      value: 'proUnlimited',
    },
    {
      label: 'Pro',
      value: 'pro',
    },
  ]);
  const [countryList, setCountryList] = useState([]);

  const [userPlanDetails, setUserPlanDetails] = useState({
    planName: userPlanDetailStore?.userPlanDetails ? userPlanDetailStore?.userPlanDetails?.plan_name : 'Free Forever',
    isFreePlan: userPlanDetailStore?.userPlanDetails ? false : true,
    planAmount: userPlanDetailStore?.userPlanDetails ? userPlanDetailStore?.userPlanDetails?.plan_price : '$0/mo',
    creditsIncluded: userPlanDetailStore?.proAccountDetails?.total_credits == -1 ? 'Unlimited' : userPlanDetailStore?.proAccountDetails?.total_credits,
    creditsUsed: userPlanDetailStore?.proAccountDetails?.monthly_used_credits,
  });
  const borderedBox = {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '4px',
    boxShadow: 'none',
    border: '1px solid rgb(226, 226, 226)',
    padding: '12px',
  };
  const [emailError, setEmailError] = useState({ status: false, message: ' ' });
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    message: ' ',
  });
  const [lastNameError, setLastNameError] = useState({
    status: false,
    message: ' ',
  });
  const [roleError, setRoleError] = useState({
    status: false,
    message: ' ',
  });
  const [countryError, setCountryError] = useState({
    status: false,
    message: ' ',
  });
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const nameRegex = regexes.nameValidation;
  const emailRegex = regexes.emailValidation;
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');

    if (tab === 'Plan') {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
    getRolesList();
    getLocationList();
    getUserDetails();
  }, []);

  useEffect(() => {
    const planDetails = {
      planName: userPlanDetailStore?.userPlanDetails ? userPlanDetailStore?.userPlanDetails?.plan_name : 'Free Forever',
      isFreePlan: userPlanDetailStore?.userPlanDetails ? false : true,
      planAmount: userPlanDetailStore?.userPlanDetails ? userPlanDetailStore?.userPlanDetails?.plan_price : '$0/mo',
      creditsIncluded: userPlanDetailStore?.proAccountDetails?.total_credits == -1 ? 'Unlimited' : userPlanDetailStore?.proAccountDetails?.total_credits,
      creditsUsed: userPlanDetailStore?.proAccountDetails?.monthly_used_credits,
    };
    setUserPlanDetails(planDetails);
  }, [userPlanDetailStore]);

  const getRolesList = async () => {
    let response = await dispatch(GetRolesList());
    if (response && response?.length !== 0) {
      setRoleOptions(response?.sort((a, b) => a.sortby - b.sortby));
    }
  };
  const updateEmail = async (values) => {
    try {
      const mainValues = {
        email: values.toLowerCase(),
      };
      setDisableEmailChngBtns(true);
      const response = await dispatch(UpdateEmail(mainValues));
      if (response) {
        setDisableEmailChngBtns(false);
        setshowSaveEmailModal(false);
        setShowOtpModal(true);
      } else {
        setDisableEmailChngBtns(false);
        setshowSaveEmailModal(false);
        setUserEmail(userEmailCopy);
        setEmailStatus(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateRole = (values) => {
    if (!values || values === '') {
      setRoleError({
        status: true,
        message: errors.accounts.selectARole,
      });
      return;
    }
    if (values === selectedRole) {
      setRoleError({
        status: true,
        message: errors.accounts.alreadyActiveRole,
      });
      return;
    }
    setShowRoleChangeModal(true);
  };
  const submitRoleChange = async () => {
    try {
      const mainValues = {
        role: selectedRoleOption,
        workspace: userDetailStore?.workspace?.name,
      };
      const response = await dispatch(UpdateRole(mainValues));
      if (response) {
        setRoleError({ error: false, message: ' ' });
        setShowRoleChangeModal(false);
        setSelectedRoleOption(null);
        let userDtl = { ...userDetailStore };
        userDtl.role = mainValues.role;
        dispatch(setUserDetails(userDtl));
        getUserDetails();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEmailChange = () => {
    if (userEmail === userEmailCopy) {
      setEmailError({
        status: true,
        message: errors.accounts.emailNotChanged,
      });
    } else if (!emailRegex.test(userEmail)) {
      setEmailError({
        status: true,
        message: errors.accounts.emailNotCorrect,
      });
    } else {
      setshowSaveEmailModal(true);
    }
  };

  const getUserDetails = async () => {
    const response = await getUserDetailService(dispatch);
    if (response) {
      setFirstName(response?.first_name || '');
      setOriginalFirstName(response?.first_name || '');
      setLastName(response?.last_name || '');
      setOriginalLastName(response?.last_name || '');
      setSelectedCountry(response?.location || '');
      setOriginalSelectedCountry(response?.location || '');
      setSelectedRole(response?.role || '');
      setUserEmail(response?.email || '');
      setUserEmailCopy(response?.email || '');
      setShowSkeleton(false);
    }
  };

  const getLocationList = async () => {
    const response = await getLocationListService();
    if (response && response.length !== 0) {
      setCountryList([...response]);
    }
  };
  const validateDetails = (fieldName) => {
    switch (fieldName) {
      case 'firstName':
        if (firstName === originalFirstName) {
          return;
        }
        if (!nameRegex.test(firstName)) {
          setFirstNameError({
            status: true,
            message: errors.accounts.incorrectFirstName,
          });
          return;
        }
        if (firstName.length > limitations.nameMaxLimitation) {
          setFirstNameError({
            status: true,
            message: errors.accounts.invalidLength,
          });
          return;
        }
        if (firstName.length < limitations.nameMinLimitation) {
          setFirstNameError({
            status: true,
            message: errors.accounts.invalidMinLength,
          });
          return;
        }
        setFirstNameError({
          status: false,
          message: ' ',
        });
        saveDetails();
        break;
      case 'lastName':
        if (lastName === originalLastName) {
          return;
        }
        if (!nameRegex.test(lastName)) {
          setLastNameError({
            status: true,
            message: errors.accounts.incorrectLastName,
          });
          return;
        }
        if (lastName.length > limitations.nameMaxLimitation) {
          setLastNameError({
            status: true,
            message: errors.accounts.invalidLength,
          });
          return;
        }
        if (lastName.length < limitations.nameMinLimitation) {
          setLastNameError({
            status: true,
            message: errors.accounts.invalidMinLength,
          });
          return;
        }
        setLastNameError({
          status: false,
          message: ' ',
        });
        saveDetails();
        break;
      case 'country':
        if (selectedCountry === originalSelectedCountry) {
          return;
        }
        saveDetails();
        break;
      default:
        break;
    }
  };

  const saveDetails = async () => {
    let payload = {};
    if (firstName && !firstNameError.status) {
      payload['firstName'] = firstName;
    }
    if (lastName && !lastNameError.status) {
      payload['lastName'] = lastName;
    }
    if (selectedCountry) {
      payload['location'] = selectedCountry;
    }
    const response = await updateUserDetailsService(payload);
    if (response) {
      getUserDetails();
      setFirstNameError({ status: false, message: ' ' });
      setLastNameError({ status: false, message: ' ' });
      setCountryError({ status: false, message: ' ' });
    }
  };
  const [showOtpModal, setShowOtpModal] = useState(false);
  const verifyOtp = async (otp) => {
    const payload = {
      newOtp: otp,
    };
    const response = await verifyEmailChngOtpService(payload);
    if (response) {
      setShowOtpModal(false);
      setShowSuccessModal(true);
    } else {
      return false;
    }
  };
  const logoutUser = () => {
    clearStorage();
    window.location.href = '/login';
  };
  const cancelRequest = () => {
    setShowOtpModal(false);
    setUserEmail(userEmailCopy);
    setEmailStatus(true);
  };
  const resendOtp = async () => {
    const payload = {
      email: userEmail,
    };
    const response = await dispatch(UpdateEmail(payload));
    if (response) {
      return true;
    }
  };
  return (
    <Box className="mt1 ml1 mr1">
      <Typography component={'h1'} variant="dashboardCompHeading">
        {labels.accounts.title}
      </Typography>
      <Box className="mt1 borderBottom2 borderColorGrey">
        <Tabs
          TabIndicatorProps={{ className: 'customIndicator1' }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab
            className="dashboardTab1 maxWidth300 textAlignLeft"
            label={labels.accounts.generalTabLabel}
            iconPosition="start"
            icon={<GradingIcon fontSize="small" />}
            disableRipple={true}
          />
          <Tab
            className="dashboardTab1 maxWidth300 textAlignLeft"
            label={labels.accounts.planTab}
            iconPosition="start"
            icon={<AttachMoneyIcon fontSize="small" />}
            disableRipple={true}
          />
        </Tabs>
      </Box>
      {selectedTab === 0 ? (
        <Box className="mt1_5 ml1">
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item lg={4}>
              {!showSkeleton ? (
                <>
                  <InputLabel
                    htmlFor={'firstName'}
                    shrink={false}
                    className="dashboardLabel1 overflowVisible"
                  >
                    <Typography className="displayFlex alignItemsCenter flexWrap whitespaceBreakSpace">
                      {labels.accounts.firstNameLabel}
                    </Typography>
                  </InputLabel>
                  <TextField
                    sx={{ marginTop: 1 }}
                    className="textFieldType1"
                    id={'firstName'}
                    onKeyDown={(e) => {
                      if (e.which === 32) {
                        return false;
                      }
                    }}
                    fullWidth
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value.replace(/\s/g, ''));
                      setFirstNameError({ status: false, message: ' ' });
                    }}
                    onBlur={() => {
                      validateDetails('firstName');
                    }}
                  />
                  <FormHelperText error={firstNameError.status}>
                    {firstNameError.message}
                  </FormHelperText>
                </>
              ) : (
                <AccountSkeleton />
              )}
            </Grid>
            <Grid item lg={4}>
              {!showSkeleton ? (
                <>
                  <InputLabel
                    htmlFor={'lastName'}
                    shrink={false}
                    className="dashboardLabel1 overflowVisible"
                  >
                    <Typography className="displayFlex alignItemsCenter flexWrap whitespaceBreakSpace">
                      {labels.accounts.lastNameLabel}
                    </Typography>
                  </InputLabel>
                  <TextField
                    sx={{ marginTop: 1 }}
                    className="textFieldType1"
                    id={'lastName'}
                    fullWidth
                    value={lastName}
                    onKeyDown={(e) => {
                      if (e.which === 32) {
                        return false;
                      }
                    }}
                    onChange={(e) => {
                      setLastName(e.target.value.replace(/\s/g, ''));
                      setLastNameError({ status: false, message: ' ' });
                    }}
                    onBlur={() => {
                      validateDetails('lastName');
                    }}
                  />
                  <FormHelperText error={lastNameError.status}>
                    {lastNameError.message}
                  </FormHelperText>
                </>
              ) : (
                <AccountSkeleton />
              )}
            </Grid>
            <Grid item lg={4}>
              {!showSkeleton ? (
                <>
                  <InputLabel
                    htmlFor={'location'}
                    shrink={false}
                    className="dashboardLabel1 overflowVisible"
                  >
                    <Typography className='className="displayFlex alignItemsCenter flexWrap whitespaceBreakSpace"'>
                      {labels.accounts.countryLabel}
                    </Typography>
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="country"
                      id="country"
                      fullWidth
                      className="dashboardSelect1"
                      displayEmpty
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return placeholders.accounts.selectCountry;
                        } else {
                          for (let i = 0; i < countryList.length; i++) {
                            if (countryList[i].id === selected) {
                              return countryList[i].name;
                            }
                          }
                        }
                      }}
                      value={selectedCountry}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                        setCountryError({ status: false, message: ' ' });
                      }}
                      onBlur={() => {
                        validateDetails('country');
                      }}
                    >
                      {countryList.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText error={countryError.status}>
                    {countryError.message}
                  </FormHelperText>
                </>
              ) : (
                <AccountSkeleton />
              )}
            </Grid>
            <Grid item lg={4}>
              {!showSkeleton ? (
                <>
                  <InputLabel
                    sx={{ overflow: 'visible' }}
                    htmlFor={'firstName'}
                    shrink={false}
                    className="dashboardLabel1"
                  >
                    <Typography className='className="displayFlex alignItemsCenter flexWrap whitespaceBreakSpace"'>
                      {labels.accounts.roleLabel}
                    </Typography>
                  </InputLabel>
                  <Box className="displayFlex alignItemsCenter">
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        className="dashboardSelect1"
                        value={selectedRoleOption || selectedRole}
                        displayEmpty
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected) {
                            return placeholders.accounts.selectRole;
                          } else {
                            for (let i = 0; i < roleOptions.length; i++) {
                              if (roleOptions[i].id === selected) {
                                return roleOptions[i].roleName;
                              }
                            }
                          }
                        }}
                        onChange={(e) => {
                          setSelectedRoleOption(e.target.value);
                          setRoleError({ error: false, message: ' ' });
                        }}
                      >
                        {roleOptions.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.roleName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="displayFlex justifySpaceBetween">
                    <FormHelperText className="mt12" error={roleError.status}>
                      {roleError.message}
                    </FormHelperText>
                    {selectedRoleOption && (
                      <Button
                        disableRipple={true}
                        className="minWidth50 textTransformNone mt9"
                        variant="purpleBtnTransparent"
                        onClick={() => {
                          updateRole(selectedRoleOption);
                        }}
                      >
                        {labels.accounts.saveBtnLabel}
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <AccountSkeleton />
              )}
            </Grid>
            <Grid item lg={4}>
              {!showSkeleton ? (
                <>
                  <InputLabel
                    htmlFor={'email'}
                    shrink={false}
                    className="dashboardLabel1 overflowVisible"
                    value="mail.com"
                  >
                    <Typography className='className="displayFlex alignItemsCenter flexWrap whitespaceBreakSpace"'>
                      {labels.accounts.emailLabel}
                    </Typography>
                  </InputLabel>
                  <Box className="displayFlex" flexDirection={'column'}>
                    <TextField
                      sx={{ marginTop: 1 }}
                      className="textFieldType1"
                      id={'email'}
                      fullWidth
                      value={userEmail}
                      onChange={(e) => {
                        setEmailError({
                          status: false,
                          message: ' ',
                        });
                        setUserEmail(e.target.value);
                      }}
                      disabled={emailStatus}
                    />
                    {emailStatus === true ? (
                      <Box className="textAlignRight">
                        <Button
                          disableRipple={true}
                          className="mt5p textTransformNone"
                          variant="purpleBtnTransparent"
                          onClick={() => {
                            setEmailStatus(false);
                          }}
                        >
                          {labels.accounts.editEmailLabel}
                        </Button>
                      </Box>
                    ) : (
                      <Box className="displayFlex justifySpaceBetween">
                        <FormHelperText
                          className="mt8p"
                          error={emailError.status}
                        >
                          {emailError.message}
                        </FormHelperText>
                        <Box className="textAlignRight">
                          <Button
                            disableRipple={true}
                            className="mt5p textAlignCenter textTransformNone minWidth70"
                            variant="purpleBtnTransparent"
                            onClick={() => {
                              handleEmailChange();
                            }}
                          >
                            {labels.accounts.saveBtnLabel}
                          </Button>
                          <Button
                            disableRipple={true}
                            className="minWidth70 textTransformNone mt5p textAlignCenter ml5p"
                            variant="purpleBtnTransparent"
                            onClick={() => {
                              setEmailStatus(true);
                              setEmailError({ status: false, message: ' ' });
                              setUserEmail(userEmailCopy);
                            }}
                          >
                            {labels.accounts.cancelBtnLabel}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              ) : (
                <AccountSkeleton />
              )}
            </Grid>
          </Grid>
        </Box>
      ) : selectedTab === 1 ? (
        <Box mt={2}>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item lg={4}>
              <Box sx={borderedBox}>
                <Typography variant="dashboardCompHeading2">
                  {labels.accounts.planLabel}
                </Typography>
                <Typography>{userPlanDetails.planName}</Typography>
                <Typography mb={3}>{userPlanDetails.planAmount}</Typography>
                <Button
                  disableRipple={true}
                  className="my5px mx0px textTransformNone"
                  sx={{ margin: '5px 0px' }}
                  fullWidth
                  variant="blueBtnLight"
                  onClick={() => {
                    setShowUpgradeModal(true);
                  }}
                  disabled={(userPlanDetailStore?.userPlanDetails?.id == 1) ? false : true}
                >
                  {labels.accounts.upgradeLabel}
                </Button>
                <Button
                  disableRipple={true}
                  className="my5px mx0px textTransformNone"
                  sx={{ margin: '5px 0px' }}
                  fullWidth
                  variant="blueBtnLight"
                  disabled={(userPlanDetailStore?.userPlanDetails?.id == 1) ? true : false}
                >
                  {labels.accounts.downgradeLabel}
                </Button>
                <Button
                  disableRipple={true}
                  className="my5px mx0px textTransformNone"
                  sx={{ margin: '5px 0px' }}
                  fullWidth
                  variant="blueBtnLight"
                  disabled={userPlanDetailStore?.userPlanDetails?.id ? false : true}
                >
                  {labels.accounts.cancelSubscription}
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box sx={borderedBox}>
                <Typography variant="dashboardCompHeading2">
                  {labels.accounts.proCredit}
                </Typography>
                <Typography>
                  {labels.accounts.included} {userPlanDetails.creditsIncluded}
                </Typography>
                <Typography>
                  {labels.accounts.used}
                  {userPlanDetails.creditsUsed}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box sx={borderedBox}>
                <Typography variant="dashboardCompHeading2">
                  {labels.accounts.payments}
                </Typography>
                <Typography>{labels.accounts.paymentHistory}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ''
      )}
      {
        <CustomDialog
          handleOpen={showUpgradeModal}
          dialogTitle="Upgrade"
          handleClose={() => {
            setShowUpgradeModal(false);
          }}
        >
          <Typography variant={'customStyle3'}>
            {labels.accounts.proLabel}
          </Typography>
          <Typography mt={2}>{labels.accounts.selectPlan}</Typography>
          <Typography mt={2} className="dashboardLabel1">
            {labels.accounts.selectPlanHeading}
          </Typography>
          <CustomRadioGroup
            ariaLabel={'upgradeModal'}
            radioGroupName={'upgradeModal'}
            radioOptions={planOptions}
          />
          <Button
            disableRipple={true}
            onClick={() => {
              navigate('/pro');
            }}
            fullWidth
            variant="purpleBtnLight"
          >
            {labels.accounts.learnMore}
          </Button>
          <Button
            disableRipple={true}
            fullWidth
            className="mt16px"
            variant="purpleBtnDark"
          >
            {labels.accounts.upgradeNow}
          </Button>
        </CustomDialog>
      }
      {showSaveEmailModal && (
        <CustomDialog handleOpen={showSaveEmailModal} showClose={false}>
          <Typography className="textAlignCenter fontWeightBold">
            {warnings.accounts.note}
          </Typography>
          <Typography className="textAlignCenter mt1">
            {warnings.accounts.emailChange}
          </Typography>
          <Typography className="textAlignCenter mt1">
            {warnings.accounts.confirmText}
          </Typography>
          <Box className="textAlignCenter mt1_5">
            <Button
              disableRipple={true}
              variant="buttonVariant1"
              disabled={disableEmailChngBtns}
              onClick={() => {
                updateEmail(userEmail);
              }}
            >
              {warnings.accounts.confirmBtnText}
            </Button>
            <Button
              disableRipple={true}
              variant="buttonVariant1"
              disabled={disableEmailChngBtns}
              onClick={() => {
                setshowSaveEmailModal(false);
                setEmailStatus(true);
                setUserEmail(userEmailCopy);
              }}
            >
              {warnings.accounts.cancelBtnText}
            </Button>
          </Box>
        </CustomDialog>
      )}
      {showOtpModal && (
        <AccountOtpModal
          otpVerifyHandler={verifyOtp}
          userEmail={userEmail}
          showModal={showOtpModal}
          handleCancel={cancelRequest}
          resendOtpHandler={resendOtp}
        />
      )}
      {showSuccessModal && <SuccessModal handleOkay={logoutUser} />}
      {showRoleChangeModal && (
        <CustomDialog
          handleOpen={showRoleChangeModal}
          dialogTitle={labels.accounts.roleChangeModal.title}
          handleClose={() => {
            setShowRoleChangeModal(false);
          }}
        >
          <Typography className="textAlignCenter fontWeightBold">
            {labels.accounts.roleChangeModal.subHeading}
          </Typography>
          <Typography className="textCenter" mt={2}>
            {labels.accounts.roleChangeModal.paraText}
          </Typography>
          <Box className="displayFlex textCenter justifyCenter alignContentCenter alignItemsCenter mt2">
            <Button
              disableRipple={true}
              variant="buttonVariant1"
              className="mr1 textTransformNone"
              onClick={() => {
                submitRoleChange();
              }}
            >
              {labels.accounts.roleChangeModal.agreeBtnLabel}
            </Button>
            <Button
              className="textTransformNone"
              disableRipple={true}
              variant="buttonVariant1"
              onClick={() => {
                setShowRoleChangeModal(false);
                setSelectedRoleOption(null);
              }}
            >
              {labels.accounts.roleChangeModal.disagreeBtnLabel}
            </Button>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );
};
export default AccountComponent;
