import { Tabs, Tab } from '@mui/material';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

const CommentsTabs = ({
  selectedTab = 0,
  handleTabChange = () => { },
  tabData = [],
}) => {
  return (
    <Tabs
      TabIndicatorProps={{ className: 'customIndicator1' }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      <Tab
        key='commentCustomTab1'
        className="dashboardTab1 textAlignLeft maxWidth220"
        disableRipple={true}
        label='Comments'
        iconPosition="start"
        icon={<InsertCommentOutlinedIcon fontSize='small' />}
      />
      <Tab
        key='commentCustomTab2'
        className="dashboardTab1 textAlignLeft maxWidth220"
        disableRipple={true}
        label='Changes'
        iconPosition="start"
        icon={<BorderColorOutlinedIcon fontSize='small' />}
      />
    </Tabs>
  );
};

export default CommentsTabs;
