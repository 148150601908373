import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
  FormHelperText,
  Slider,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { labels } from '../../../data/constants';
import CompetitorMarketSubCategoryProfile from './CompetitorMarketSubCategoryProfile';
import {
  linkCompetitorToMarketService,
  updateCompetitorService,
} from '../../../Services/ResearchServices';
import { useSelector, useDispatch } from 'react-redux';
import ResearchDeleteModal from '../ResearchDeleteModal';
import { saved, saving, couldnotSave } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';

const teamSize = [
  {
    value: 0,
    label: '1-50',
  },
  {
    value: 25,
    label: '51-200',
  },
  {
    value: 50,
    label: '201-1000',
  },
  {
    value: 75,
    label: '1001-5000',
  },
  {
    value: 100,
    label: '5000+',
  },
];
const funding = [
  {
    value: 0,
    label: 'Minimal',
  },
  {
    value: 25,
    label: '$1-10M',
  },
  {
    value: 50,
    label: '$11-50M',
  },
  {
    value: 75,
    label: '$51-500M',
  },
  {
    value: 100,
    label: '$501M+',
  },
];
const CompetitorInfoBlock = ({
  competitorData = null,
  mwscData = [],
  fetchCompetitorDetail = () => {},
  assessmentList = [],
  fetchAssessmentsList = () => {},
  deleteCompetitor = () => {},
  fetchMarketWithSubCategories = () => {},
  fetchCompetitorsList = () => {},
}) => {
  const [competitorDetail, setCompetitorDetail] = useState(null);
  const [competitorDetailCopy, setCompetitorDetailCopy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nameEdit, setNameEdit] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState(null);
  const [showDltCompModal, setShowDltCompModal] = useState(false);
  const open = Boolean(anchorEl);
  const [nameError, setNameError] = useState({
    status: false,
    msg: '',
  });
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (competitorData) {
      setCompetitorDetail(JSON.parse(JSON.stringify(competitorData)));
      setCompetitorDetailCopy(JSON.parse(JSON.stringify(competitorData)));
      let selectedSubCats = competitorData?.relatedMarketSubCategories?.map(
        (option) => option?.id
      );
      setSelectedSubCategories(selectedSubCats);
    }
  }, [competitorData]);
  useEffect(() => {
    fetchMarketWithSubCategories();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [fyError, setFYError] = useState({
    status: '',
    msg: '',
  });
  const [ARError, setARError] = useState({
    status: '',
    msg: '',
  });
  const updateCompetitorName = async () => {
    try {
      if (
        competitorDetailCopy?.competitorName ===
        competitorDetail?.competitorName
      ) {
        setNameEdit(false);
        return;
      }
      if (!competitorDetail?.competitorName) {
        setNameError({
          status: true,
          msg: 'Name cannot be blank!',
        });
        return;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.competitorId,
        competitorName: competitorDetail?.competitorName,
      };
      dispatch(saving());
      const response = await updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setCompetitorDetailCopy((prevState) => {
          return {
            ...prevState,
            competitorName: competitorDetail?.competitorName,
          };
        });
        setNameEdit(false);
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetail.competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateTeamSize = async (receivedValue) => {
    try {
      let valueLabel = '';
      switch (receivedValue) {
        case 0:
          valueLabel = '1-50';
          break;
        case 25:
          valueLabel = '51-200';
          break;
        case 50:
          valueLabel = '201-1000';
          break;
        case 75:
          valueLabel = '1001-5000';
          break;
        case 100:
          valueLabel = '5000+';
          break;
        default:
          break;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.competitorId,
        team_size: valueLabel,
      };
      dispatch(saving());
      const response = updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setCompetitorDetail((prevState) => {
          return {
            ...prevState,
            teamSize: receivedValue,
          };
        });
        setCompetitorDetailCopy((prevState) => {
          return {
            ...prevState,
            teamSize: receivedValue,
          };
        });
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailCopy?.competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateFunding = async (receivedValue) => {
    try {
      let valueLabel = '';
      switch (receivedValue) {
        case 0:
          valueLabel = 'Minimal';
          break;
        case 25:
          valueLabel = '$1-10M';
          break;
        case 50:
          valueLabel = '$11-50M';
          break;
        case 75:
          valueLabel = '$51-500M';
          break;
        case 100:
          valueLabel = '$501M+';
          break;
        default:
          break;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.competitorId,
        funding: valueLabel,
      };
      dispatch(saving());
      const response = updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setCompetitorDetail((prevState) => {
          return {
            ...prevState,
            funding: receivedValue,
          };
        });
        setCompetitorDetailCopy((prevState) => {
          return {
            ...prevState,
            funding: receivedValue,
          };
        });
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetail?.competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateDetails = async (objectField, payloadField) => {
    try {
      if (fyError.status || ARError.status) {
        return;
      }
      if (competitorDetail[objectField] === competitorDetailCopy[objectField]) {
        return;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.competitorId,
      };
      payload[payloadField] = competitorDetail[objectField];
      dispatch(saving());
      const response = await updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        let data = competitorDetailCopy;
        data[objectField] = competitorDetail[objectField];
        setCompetitorDetailCopy(data);
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetail.competitorName}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  const addMarketToCompetitor = async (
    subCatIdReceived,
    subCatNameReceived
  ) => {
    try {
      let payload = {
        workspaceId: +userWorkspace?.id,
        competitorId: +competitorDetail?.competitorId,
        subcategoryId: +subCatIdReceived,
      };
      dispatch(saving());
      const response = await linkCompetitorToMarketService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchCompetitorDetail(competitorDetail?.competitorId);
        let changeLogPayload = {
          changeDetail: `Competitor added to '${subCatNameReceived}' Sub-Category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const fetchCompDtls = async () => {
    fetchCompetitorDetail(competitorDetail?.competitorId);
  };

  const handleCompetitorDelete = async (receivedValue) => {
    const response = await deleteCompetitor(receivedValue);
    if (response) {
      setShowDltCompModal(false);
    }
  };

  const handleAnswerChange = (answerValue, fieldName) => {
    switch (fieldName) {
      case 'foundingYear':
        let date = new Date();
        let currentYear = date.getFullYear();
        if (answerValue > currentYear) {
          setCompetitorDetail((prevState) => {
            return {
              ...prevState,
              foundingYear: answerValue,
            };
          });
          setFYError({
            status: true,
            msg: 'Cannot be greater than current year!',
          });
        } else if (answerValue <= 0) {
          setCompetitorDetail((prevState) => {
            return {
              ...prevState,
              foundingYear: answerValue,
            };
          });
          setFYError({
            status: true,
            msg: 'Cannot be less than or equal to 0!',
          });
        } else {
          setCompetitorDetail((prevState) => {
            return {
              ...prevState,
              foundingYear: answerValue,
            };
          });
        }
        break;
      case 'annualRevenue':
        if (answerValue < 0) {
          setCompetitorDetail((prevState) => {
            return {
              ...prevState,
              annualRevenue: answerValue,
            };
          });
          setARError({
            status: true,
            msg: 'Cannot be less than 0!',
          });
        }
        setCompetitorDetail((prevState) => {
          return {
            ...prevState,
            annualRevenue: answerValue,
          };
        });
        break;
      default:
        break;
    }
  };
  return (
    <Box className="ml2">
      {!nameEdit ? (
        <Box className="displayFlex alignItemsCenter">
          <Typography
            component={'h4'}
            variant="dashboardCompHeading"
            className="fontSize18 textTrasformCapital mr1"
          >
            {competitorDetailCopy?.competitorName}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={() => {
              setNameEdit(true);
            }}
          >
            <CreateOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowDltCompModal(true);
            }}
            aria-label="delete"
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <Box className="displayFlex alignItemsCenter justifySpaceBetween">
            <TextField
              className="textFieldType4 mr1 mt0 mb0"
              value={competitorDetail?.competitorName}
              onChange={(e) => {
                setCompetitorDetail((prevState) => {
                  return { ...prevState, competitorName: e.target.value };
                });
                setNameError({ status: false, msg: '' });
              }}
            />
            <Button
              className="textAlignCenter textTransformNone displayFlex alignItemsCenter"
              variant="buttonVariant1"
              startIcon={<DoneOutlinedIcon fontSize="small" />}
              onClick={updateCompetitorName}
            >
              Done
            </Button>
            {/* <Button
              className="textAlignCenter textTransformNone"
              variant="purpleBtnTransparent"
              onClick={() => {
                setCompetitorDetail((prevState) => {
                  return {
                    ...prevState,
                    competitorName: competitorDetailCopy?.competitorName,
                  };
                });
                setNameError({ status: false, msg: '' });
                setNameEdit(false);
              }}
            >
              Cancel
            </Button> */}
          </Box>
          <FormHelperText className="mt05" error={nameError.status}>
            {nameError.msg}
          </FormHelperText>
        </Box>
      )}
      <Box className="backgroundLightGrey borderRadius8 padding12x16 mt1">
        <InputLabel
          sx={{
            overflow: 'visible',
            display: 'flex',
            alignItems: 'center',
          }}
          shrink={false}
          className="dashboardLabel1"
        >
          <Typography>Team Size</Typography>
        </InputLabel>
        <Slider
          className="customSlider"
          step={25}
          marks={teamSize}
          value={
            competitorDetail?.teamSize !== undefined
              ? competitorDetail.teamSize
              : 50
          }
          min={0}
          max={100}
          sx={{
            width: '95%',
            marginLeft: '1rem',
          }}
          onChange={(e, newValue) => {
            updateTeamSize(newValue);
          }}
        />
        <InputLabel
          sx={{
            overflow: 'visible',
            display: 'flex',
            alignItems: 'center',
          }}
          shrink={false}
          className="dashboardLabel1"
        >
          <Typography className="mt1">Funding (in USD)</Typography>
        </InputLabel>
        <Slider
          className="customSlider"
          step={25}
          marks={funding}
          value={
            competitorDetail?.funding !== undefined
              ? competitorDetail.funding
              : 50
          }
          min={0}
          max={100}
          sx={{
            width: '95%',
            marginLeft: '1rem',
          }}
          onChange={(e, newValue) => {
            updateFunding(newValue);
          }}
        />
        <Grid container className="mt1" columnSpacing={2}>
          <Grid item lg={6}>
            <InputLabel
              sx={{
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">
                Annual Revenue (millions)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4 backgroundWhite"
              type="number"
              value={competitorDetail?.annualRevenue || ''}
              onChange={(e) => {
                setARError({ status: false, msg: '' });
                handleAnswerChange(e.target.value, 'annualRevenue');
              }}
              onBlur={() => {
                updateDetails('annualRevenue', 'annual_revenue');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment className="ml05" position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText error={ARError.status}>
              {ARError.msg}
            </FormHelperText>
          </Grid>
          <Grid item lg={6}>
            <InputLabel
              sx={{
                overflow: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
              shrink={false}
              className="dashboardLabel1"
            >
              <Typography variant="customStyle1">
                {labels.research.commonLabels.foundingYear}
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4 backgroundWhite"
              type="number"
              value={competitorDetail?.foundingYear || ''}
              onChange={(e) => {
                setFYError({ status: false, msg: '' });
                handleAnswerChange(e.target.value, 'foundingYear');
              }}
              onBlur={() => {
                updateDetails('foundingYear', 'founding_year');
              }}
            />
            <FormHelperText error={fyError.status}>
              {fyError.msg}
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>
      <Typography
        component={'h4'}
        variant="dashboardCompHeading"
        className="fontSize18 textTrasformCapital mr1 mt2"
      >
        Market Sub-Category Profiles
      </Typography>
      {competitorData?.relatedMarketSubCategories?.length !== 0 && (
        <>
          {competitorData?.relatedMarketSubCategories?.map(
            (subCategoryOption, index) => (
              <CompetitorMarketSubCategoryProfile
                key={'competitorSubCategoryOption' + index}
                subCategoryProfile={subCategoryOption}
                fetchCompetitorDetail={fetchCompDtls}
                competitorId={competitorData?.competitorId}
                competitorName={competitorDetailCopy?.competitorName}
                workspaceId={userWorkspace?.id}
                assessmentList={assessmentList}
                fetchAssessmentsList={fetchAssessmentsList}
              />
            )
          )}
        </>
      )}

      <Button
        className="mt1 mb2 textTrasformCapital"
        variant="buttonVariant1"
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        disabled={mwscData?.length ? false : true}
        aria-expanded={open ? 'true' : undefined}
      >
        + Add to Sub-Category
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="mwsc-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className="minWidth300"
      >
        {mwscData.map((mwscOption, index) => (
          <Box key={'menuContainer' + index}>
            <Typography variant="disabledText">
              {mwscOption?.market_name}
            </Typography>
            {mwscOption?.sub_categories?.map((subCatOption, index) => (
              <MenuItem
                key={'subCatOption' + index}
                disabled={
                  selectedSubCategories?.includes(subCatOption?.sub_category_id)
                    ? true
                    : false
                }
                className="customMenuItem"
                onClick={() => {
                  addMarketToCompetitor(
                    subCatOption?.sub_category_id,
                    subCatOption?.sub_category_name
                  );
                  handleClose();
                }}
              >
                <Typography className="ml1">
                  {subCatOption?.sub_category_name}
                </Typography>
              </MenuItem>
            ))}
            <Divider />
          </Box>
        ))}
      </Menu>
      {showDltCompModal && (
        <ResearchDeleteModal
          title="Delete Competitor"
          message1="Are you sure you want to delete this competitor and all its sub-category profile data?"
          confirmDeleteMarket={() => {
            handleCompetitorDelete(competitorDetail?.competitorId);
          }}
          handleClose={() => {
            setShowDltCompModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default CompetitorInfoBlock;
