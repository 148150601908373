import { Typography, Box, Button } from '@mui/material';
import { labels } from '../../data/constants';
import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';

const ResearchDeleteModal = ({
  handleClose = () => { },
  confirmDeleteMarket = () => { },
  title = '',
  message1 = '',
  message2 = '',
  isDisabled = false,
}) => {
  return (
    <CustomDialog
      dialogTitle={title}
      showClose={false}
      width={'xs'}
      handleOpen={true}
      headingVariant="modalHeading1"
    >
      <Typography>{message1}</Typography>
      {message2 && (
        <Typography className="mt1">
          <Typography component={'span'} fontWeight={'bold'}>
            {labels.research.marketTab.marketDeleteModal.warningLabel}
          </Typography>
          {message2}
        </Typography>
      )}

      <Box className="justifyFlexEnd displayFlex mt1_5">
        <Button
          disabled={isDisabled}
          className="mr1"
          variant="textButton6"
          onClick={handleClose}
        >
          {labels.commonLabels.cancelLabel}
        </Button>
        <Button
          className='textTrasformCapital'
          disabled={isDisabled}
          variant="contained"
          color="error"
          onClick={confirmDeleteMarket}
        >
          {labels.commonLabels.deleteLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default ResearchDeleteModal;
