import { Box, Typography, Stack, Chip } from '@mui/material';
import { labels } from '../../../data/constants';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useState } from 'react';
import AddAssessmentModal from '../AddAssessmentModal';
import ChartPanel from './ChartPanel';
import {
  createOverallAssessmentService,
  updateMarketSubCategoryService,
} from '../../../Services/ResearchServices';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const OverallCompetitiveAssessment = ({
  assessmentList = [],
  assessmentData = '',
  fetchCompetitorsList = () => {},
  competitorList = [],
}) => {
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  const dispatch = useDispatch();
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const updateAssessment = async (assessmentId) => {
    try {
      let payload = {
        subCategoryId: assessmentData?.subCatId,
        marketId: selectedMarketOption?.market_id,
        overallCompetitiveAssessmentid: assessmentId,
      };
      const response = await updateMarketSubCategoryService(payload, dispatch);
      if (response) {
        fetchCompetitorsList();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const addAssessment = async (receivedValue) => {
    try {
      let payload = {
        name: receivedValue,
      };
      const response = await createOverallAssessmentService(payload, dispatch);
      if (response) {
        fetchCompetitorsList();
        setShowAssessmentModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box className="mt1 borderRadius8 padding1 backgroundLightBlue">
      <Typography className="mt1 mb1 mr05" variant="dashboardCompHeading5">
        {
          labels.research.ideaTab.competitiveDifferentiationBlock
            .overAllAssessment
        }
      </Typography>
      <Box>
        <Stack direction={'row'} className="mt1 flexWrap" spacing={1}>
          {assessmentList?.map((assessmentOption, index) => (
            <Chip
              key={'assessmentOption' + index}
              label={assessmentOption?.overall_competitive_assessment_name}
              className="backgroundWhite mb05"
              clickable
              variant="outlined"
              icon={
                assessmentData?.overallCompetitiveAssessmentid ===
                assessmentOption?.id ? (
                  <DoneOutlinedIcon fontSize="small" />
                ) : (
                  ''
                )
              }
              color={
                assessmentData?.overallCompetitiveAssessmentid ===
                assessmentOption?.id
                  ? 'success'
                  : 'default'
              }
              onClick={() => {
                updateAssessment(assessmentOption?.id);
              }}
            />
          ))}
          <Chip
            label="Other+"
            className="backgroundWhite mb05"
            variant="outlined"
            clickable
            onClick={() => {
              setShowAssessmentModal(true);
            }}
          />
        </Stack>
      </Box>
      <Typography
        component={'p'}
        className="mt1"
        variant="dashboardCompHeading5"
      >
        {
          labels.research.ideaTab.competitiveDifferentiationBlock
            .strengthWeaknessHeading
        }
      </Typography>
      <ChartPanel competitorsDetail={competitorList} />
      {showAssessmentModal && (
        <AddAssessmentModal
          handleCancel={() => setShowAssessmentModal(false)}
          submitDetails={(receivedValue) => {
            addAssessment(receivedValue);
          }}
        />
      )}
    </Box>
  );
};
export default OverallCompetitiveAssessment;
