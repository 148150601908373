import { Tabs, Tab } from '@mui/material';

const ResearchTabComp = ({
  selectedTab = 0,
  handleTabChange = () => {},
  tabData = [],
}) => {
  return (
    <Tabs
      TabIndicatorProps={{ className: 'customIndicator1' }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      {tabData.length &&
        tabData?.map((tabOption, index) => (
          <Tab
            key={'customTab' + index}
            className="dashboardTab1 textAlignLeft maxWidth220"
            disableRipple={true}
            label={tabOption?.tabName}
            iconPosition="start"
            icon={tabOption?.tabIcon}
          />
        ))}
    </Tabs>
  );
};

export default ResearchTabComp;
