import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import {
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Slider,
  Switch,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { labels } from '../../data/constants';
import { useState } from 'react';
const StrengthWeaknessModal = ({
  handleCancel = () => {},
  submitDetails = () => {},
}) => {
  const handleSubmit = () => {
    if (fieldValue === '') {
      setFieldError({
        status: true,
        message: 'Name cannot be blank!',
      });
      return;
    }
    submitDetails(fieldValue, typeValue);
  };
  const [typeValue, setTypeValue] = useState('Scale');
  const [fieldValue, setFieldValue] = useState('');
  const [fieldError, setFieldError] = useState({ status: false, message: ' ' });
  const handleTypeChange = (event, newTypeValue) => {
    setTypeValue(newTypeValue);
  };

  const valueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return 'Very Weak';
      case 25:
        return 'Weak';
      case 50:
        return 'Average';
      case 75:
        return 'Strong';
      case 100:
        return 'Very Strong';
      default:
        return '';
    }
  };
  return (
    <CustomDialog
      handleOpen={true}
      showClose={false}
      width={'xs'}
      dialogTitle="Add Sub-Category Strength/Weakness"
      headingVariant="modalHeading1"
    >
      <InputLabel
        shrink={false}
        required
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.strengthWeaknessModal.nameLabel}
        </Typography>
      </InputLabel>
      <TextField
        className="textFieldType4 mt05 backgroundWhite"
        value={fieldValue}
        onChange={(e) => {
          setFieldValue(e.target.value);
          setFieldError({ status: false, msg: '' });
        }}
        fullWidth
        placeholder={labels.research.strengthWeaknessModal.nameFieldPlaceholder}
      />
      <FormHelperText className="mt05" error={fieldError.status}>
        {fieldError.message}
      </FormHelperText>
      <InputLabel
        shrink={false}
        className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible"
      >
        <Typography className="mt1">
          {labels.research.strengthWeaknessModal.typeLabel}
        </Typography>
      </InputLabel>
      <ToggleButtonGroup
        className="mt05"
        value={typeValue}
        exclusive
        onChange={handleTypeChange}
        aria-label="typeSelector"
      >
        <ToggleButton
          value={'Scale'}
          aria-label={labels.research.strengthWeaknessModal.scaleLabel}
        >
          <InputLabel
            shrink={false}
            className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible textTrasformCapital"
          >
            <Typography>
              {labels.research.strengthWeaknessModal.scaleLabel}
            </Typography>
          </InputLabel>
        </ToggleButton>
        <ToggleButton
          value={'Binary'}
          aria-label={labels.research.strengthWeaknessModal.binaryLabel}
        >
          <InputLabel
            shrink={false}
            className="dashboardLabel1 displayFlex alignItemsCenter overflowVisible textTrasformCapital"
          >
            <Typography>
              {labels.research.strengthWeaknessModal.binaryLabel}
            </Typography>
          </InputLabel>
        </ToggleButton>
      </ToggleButtonGroup>
      <Box className="mt1">
        <InputLabel
          shrink={false}
          className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter textTrasformCapital"
        >
          <Typography>Example</Typography>
        </InputLabel>
        {typeValue === 'Scale' ? (
          <Slider
            className="customSlider"
            step={25}
            defaultValue={50}
            min={0}
            max={100}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            marks
            sx={{
              width: '85%',
              marginLeft: '1rem',
            }}
          />
        ) : (
          <FormControlLabel control={<Switch />} />
        )}
      </Box>
      <Box className="justifySpaceBetween displayFlex mt1_5">
        <Button variant="buttonVariant1" onClick={handleCancel}>
          {labels.commonLabels.cancelLabel}
        </Button>
        <Button variant="buttonVariant1" onClick={handleSubmit}>
          {labels.commonLabels.submitLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};
export default StrengthWeaknessModal;
