import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Divider,
  useTheme,
  useMediaQuery,
  Drawer,
  Typography,
} from '@mui/material';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Navigation from '../Navigation/Navigation';
import { NavLink } from 'react-router-dom';
import { logo } from '../../../Assets/index';
import { useAuth } from '../../../Context/AuthProvider';
import BadgeIcon from '@mui/icons-material/Badge';
import LaunchIcon from '@mui/icons-material/Launch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector, useDispatch } from 'react-redux';
import styles from './header.module.css';
import { labels } from '../../../data/constants';
import Comments from '../../Comments/Comments';
import { listChanges, listComments } from '../../../Services/commentService';

const Header = ({ isLoggedIn = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCommentsDrawer, setOpenCommentsDrawer] = useState(false);
  const [commentsHistory, setCommentsHistory] = useState([]);
  const [changesHistory, setChangesHistory] = useState([]);
  const dispatch = useDispatch();

  const { logout } = useAuth();
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);

  const userEmail = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.email
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCommentsDrawerHandler = async () => {
    let payload = {
      workspaceId: userWorkspace.id,
    };
    setOpenCommentsDrawer(!openCommentsDrawer);
    const commentsResponse = await listComments(payload, dispatch);
    if (commentsResponse) {
      setCommentsHistory(commentsResponse);
    }
    const changesResponse = await listChanges(payload, dispatch);
    if (changesResponse) {
      setChangesHistory(changesResponse);
    }
    setOpenCommentsDrawer(!openCommentsDrawer);
  };

  return (
    <Box className={styles.headerContainer}>
      <Grid container className="borderBottomGrey">
        <Grid item xs={2}>
          <Box className="displayFlex alignItemsCenter mt1 ml1 mb05">
            {isLoggedIn && isMobile ? (
              <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
            ) : (
              ''
            )}
            <NavLink to={isLoggedIn ? '/dashboard' : ''}>
              <img
                className={isMobile ? 'height35 m05' : 'height51 m05'}
                src={logo}
                alt="Logo"
              />
            </NavLink>
          </Box>
        </Grid>
        {isLoggedIn && (
          <Grid
            item
            xs={10}
            className="alignItemsCenter pr1 displayFlex justifyFlexEnd"
          >
            <IconButton
              aria-label="comments-changes"
              color="primary"
              onClick={openCommentsDrawerHandler}
            >
              <InsertCommentOutlinedIcon />
            </IconButton>
            {isTablet && userWorkspace?.role !== 'owner' && (
              <Button
                className="fontWeight600 padding2x10 fontSize12 ml1"
                variant="outlined"
                color="warning"
              >
                {userWorkspace?.workspacePrivelege}
              </Button>
            )}
            <Button
              onClick={handleClick}
              className="ml1"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              endIcon={<KeyboardArrowDownIcon fontSize="small" />}
            >
              <AccountCircleIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              className="minWidth300"
            >
              <Typography variant="disabledText">{userEmail}</Typography>
              <MenuItem className="customMenuItem" onClick={handleClose}>
                <NavLink
                  className="displayFlex alignItemsCenter colorBlack width100 textDecorationNone"
                  to={'/account'}
                >
                  <BadgeIcon fontSize="small" className="mr10" />
                  {labels.headers.userMenu.account}
                </NavLink>
              </MenuItem>
              <MenuItem className="customMenuItem" onClick={handleClose}>
                <NavLink
                  className="displayFlex alignItemsCenter colorBlack width100 textDecorationNone"
                  to={'/workspace'}
                >
                  <LaunchIcon fontSize="small" className="mr10" />{' '}
                  {labels.headers.userMenu.workSpace}
                </NavLink>
              </MenuItem>
              <MenuItem className="customMenuItem" onClick={handleClose}>
                <HelpOutlineIcon fontSize="small" className="mr10" />
                {labels.headers.userMenu.helpFaq}
              </MenuItem>
              <Divider />
              <MenuItem className="customMenuItem" onClick={() => logout()}>
                <LogoutIcon fontSize="small" className="mr10" />
                {labels.headers.userMenu.logOut}
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box minWidth={'50vw'}>
          <Navigation />
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={openCommentsDrawer}
        onClose={() => setOpenCommentsDrawer(false)}
      >
        <Box maxWidth={'35vw'} minWidth={'35vw'} p={2}>
          <Comments
            onClose={() => setOpenCommentsDrawer(false)}
            commentsHistory={commentsHistory}
            changesHistory={changesHistory}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
