import { Box } from '@mui/material';
import AccountCompleteModal from '../../Components/CommonComponents/accountCompleteModal/AccountComplete';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ProfileCompleteModal from '../../Components/CommonComponents/ProfileComplete/ProfileCompleteModal';
import BusinessIdeasHeader from '../../Components/BusinessIdeas/BusinessIdeasHeader';
import BusinessIdeasContainer from '../../Components/BusinessIdeas/BusinessIdeasContainer';
import { ideaShareUpdateService } from '../../Services/ideaDetailService';
import {
  generateBusinessIdeasService,
  getBusinessIdeasService,
  ideaLikeDislikeService,
  ideaLockService,
  ideaVariantService,
} from '../../Services/businessIdeasService';
import { getUserDetailService } from '../../Services/AccountServices';
import {
  noLikeDislikeValue,
  profileScore_50,
  variantOffValue,
  variantOnValue,
} from '../../data/constants';
import BusinessIdeasSkeleton from '../../Components/BusinessIdeas/BusinessIdeasSkeleton';
import { saved, saving, couldnotSave } from '../../redux/saveSlice';
import { logChanges } from '../../Services/commentService';

const BusinessIdeasComp = () => {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(true);
  const [profileCompletionScore, setProfileCompletionScore] = useState(null);
  const [businessIdeas, setBusinessIdeas] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const userAllWorkspaceList = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.allWorkspaces
  );
  useEffect(() => {
    // getUserDetails();
    if (userAllWorkspaceList) {
      setScoreAsPerWorkspace();
    }
  }, [userWorkspace, userAllWorkspaceList]);
  const setScoreAsPerWorkspace = () => {
    let profileScore;
    userAllWorkspaceList?.forEach((workspaceItem) => {
      if (workspaceItem.id === userWorkspace?.id) {
        profileScore = workspaceItem.profileScore || 0;
      }
      if (profileScore >= profileScore_50) {
        getIdeasList();
      }
      setProfileCompletionScore(profileScore);
    });
  };
  const getIdeasList = async () => {
    try {
      setShowSkeleton(true);
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getBusinessIdeasService(payload);
      if (response && response?.length !== 0) {
        setBusinessIdeas(response);
        setShowSkeleton(false);
      } else {
        setShowSkeleton(false);
        setBusinessIdeas([]);
      }
    } catch (e) {
      console.log(e);
      setShowSkeleton(false);
    }
  };
  const getUserDetails = async () => {
    const response = await getUserDetailService(dispatch);
    if (response) {
      setProfileCompletionScore(response?.profileScore || 0);
      setUserRole(response?.role);
    }
  };
  const refreshIdeas = async () => {
    setShowLoader(true);
    setShowSkeleton(true);
    let payload = {
      workspaceId: +userWorkspace.id,
    };
    const response = await generateBusinessIdeasService(payload);
    if (response) {
      getIdeasList();
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  };
  const handleLockChange = async (ideaId, ideaLock) => {
    try {
      let payload = {
        ideaId: ideaId,
        locked: null,
      };
      if (ideaLock === 'true') {
        payload.locked = 'false';
      } else {
        payload.locked = 'true';
      }
      dispatch(saving());
      const response = await ideaLockService(payload, dispatch);
      if (response) {
        dispatch(saved());
        const matchingIdea = businessIdeas.find(idea => idea.id === ideaId);
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
        getIdeasList();
      } else {
        dispatch(couldnotSave());
        console.log(response);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  //Handle like and dislike for an idea.
  const likeDislikeHandler = async (
    receivedValue,
    ideaId,
    likeDislikeValue,
    feedback = null
  ) => {
    try {
      let payload = {
        ideaId: ideaId,
        likedislike: null,
        feedback: feedback,
      };
      dispatch(saving());
      if (receivedValue === likeDislikeValue) {
        payload.likedislike = 0;
      } else {
        payload.likedislike = receivedValue;
      }
      const response = await ideaLikeDislikeService(payload, dispatch);
      if (response) {
        dispatch(saved());
        getIdeasList();
        const matchingIdea = businessIdeas.find(idea => idea.id === ideaId);
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const undoIdeaDislike = async (ideaDetail) => {
    try {
      let payload = {
        ideaId: ideaDetail,
        likedislike: noLikeDislikeValue,
        feedback: '',
      };
      dispatch(saving());
      const response = await ideaLikeDislikeService(payload, dispatch);
      if (response) {
        dispatch(saved());
        getIdeasList();
        const matchingIdea = businessIdeas.find(idea => idea.id === ideaDetail);
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const handleIdeaVariantOpt = async (ideaId, variantStatus) => {
    try {
      let payload = {
        ideaId: ideaId,
        variant: null,
      };
      if (variantStatus === variantOnValue) {
        payload.variant = variantOffValue;
      } else {
        payload.variant = variantOnValue;
      }
      dispatch(saving());
      const response = await ideaVariantService(payload, dispatch);
      if (response) {
        dispatch(saved());
        getIdeasList();
        const matchingIdea = businessIdeas.find(idea => idea.id === ideaId);
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateIdeaShare = async (ideaId, shareStatus, shareLevel) => {
    try {
      let payload = {
        ideaId: ideaId,
        share: shareStatus,
        ideadetail: shareLevel,
      };
      dispatch(saving());
      const response = await ideaShareUpdateService(payload, dispatch);
      if (response) {
        dispatch(saved());
        getIdeasList();
        const matchingIdea = businessIdeas.find(idea => idea.id === ideaId);
        let changeLogPayload = {
          changeDetail: `Idea '${matchingIdea.name}' updated`,
          workspaceId: userWorkspace.id
        }
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <BusinessIdeasHeader
        fetchIdeaList={getIdeasList}
        generateIdeas={refreshIdeas}
        loader={showLoader}
        isGuest={userWorkspace?.role === 'guest' ? true : false}
      />

      {!userRole && <AccountCompleteModal showDialog={true} close={false} />}
      {userRole &&
        profileCompletionScore !== null &&
        profileCompletionScore < profileScore_50 && (
          <ProfileCompleteModal showDialog={true} close={false} />
        )}
      {profileCompletionScore >= profileScore_50 && !showSkeleton && (
        <BusinessIdeasContainer
          handleLikeDislike={likeDislikeHandler}
          handleIdeaLock={handleLockChange}
          handleIdeaUndo={undoIdeaDislike}
          ideasData={businessIdeas}
          handleVariantOption={handleIdeaVariantOpt}
          handleIdeaShare={updateIdeaShare}
          isGuest={userWorkspace?.role === 'guest' ? true : false}
        />
      )}
      {showSkeleton && <BusinessIdeasSkeleton skeletonCount={10} />}
    </Box>
  );
};

export default BusinessIdeasComp;
