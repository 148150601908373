import { Box, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';

const ChangesTabContent = ({ changesHistory }) => {
  const [fetchedChanges, setFetchedChanges] = useState([]);

  useEffect(() => {
    const sortedChanges = [...changesHistory].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setFetchedChanges(sortedChanges);
  }, [changesHistory]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (date.toDateString() === now.toDateString()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) + ', ' +
        date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
  };

  return (
    <Box>
      {fetchedChanges.map((change, index) => {
        return (
          <Box key={index} className='mt2 border1 borderColorGrey borderRadius4 boxShadow'>
            <Box>
              <Typography variant='customStyle5' className="displayFlex justifySpaceBetween">
                <Box>{change.user.first_name + ' ' + change.user.last_name}</Box>
                <Box>{formatDate(change.updatedAt)}</Box>
              </Typography>
            </Box>
            <Box className='padding1'>
              <Typography>{change.changedetail}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ChangesTabContent;
