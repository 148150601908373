import { profileLabels } from '../../../data/constants';
import Select from 'react-select';
const MultiSelectChip = ({
  handleBlur = () => {},
  selectedValue = [],
  dropDownItems,
  handleChange,
  allowMulti = true,
}) => {
  return (
    <Select
      placeholder={profileLabels.selectOption}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          marginTop: '15px',
          minHeight: '40px',
        }),
      }}
      isMulti={allowMulti}
      value={selectedValue}
      options={dropDownItems}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default MultiSelectChip;
