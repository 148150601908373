import { Box, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import IdeaSelectorComp from '../BusinessPlan/ideaSelectorComp';
import MarketSelectBlock from './Ideas/MarketSelectBlock';
import CompetitorsPanel from './Ideas/CompetitorsPanel';
import CompDiffBlock from './Ideas/CompDiffBlock';

const IdeaTabContent = ({
  ideaOptionList,
  ideaList,
  setSelectedTab = () => {},
  marketsWithSubCategories = [],
  fetchMarketWithSubCats = () => {},
  handleIdeaChange = () => {},
  AddMarket = () => {},
}) => {
  return (
    <Box>
      <Typography className="mt1 mb1">
        {labels.research.ideaTab.subHeading}
      </Typography>
      <IdeaSelectorComp
        ideaOptions={ideaOptionList}
        ideaList={ideaList}
        handleIdeaChange={handleIdeaChange}
        forComp="research"
      />
      <MarketSelectBlock
        fetchMarketWithSubCats={fetchMarketWithSubCats}
        marketWithCatDtls={marketsWithSubCategories}
        AddMarket={AddMarket}
        setSelectedTab={setSelectedTab}
      />
      <CompetitorsPanel marketWithCatDtls={marketsWithSubCategories} />
      <CompDiffBlock />
    </Box>
  );
};
export default IdeaTabContent;
