import { Box, Typography } from '@mui/material';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { labels } from '../../../data/constants';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  maintainAspectRatio: false,
  font: {
    size: 16,
  },
  plugins: {
    legend: {
      align: 'start',
      position: 'left',
    },
  },
  scales: {
    r: {
      pointLabels: {
        font: {
          size: 13,
        },
      },
      beginAtZero: true,
      min: 0,
      max: 4,
      ticks: {
        display: false,
        stepSize: 1,
      },
    },
  },
};
const ChartPanel = ({ competitorsDetail }) => {
  const [chartData, setChartData] = useState(null);

  const getStrengthNames = (strengthList) => {
    let nameList = [];
    if (strengthList?.length > 2) {
      strengthList?.forEach((strengthOption) => {
        nameList.push(strengthOption?.name);
      });
    } else {
      nameList = ['', '', ''];
    }

    return nameList;
  };

  function getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Random red value
    const g = Math.floor(Math.random() * 256); // Random green value
    const b = Math.floor(Math.random() * 256); // Random blue value

    let colorObj = {
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
      borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
    };
    return colorObj;
  }

  const getStrengthLevel = (strength) => {
    let strengthLevel = '';
    if (strength?.type === 'Binary') {
      if (strength?.strengthweaknesslevels[0]?.strengthweakness_leve) {
        if (
          strength?.strengthweaknesslevels[0]?.strengthweakness_leve === 'true'
        ) {
          strengthLevel = 4;
        } else {
          strengthLevel = 0;
        }
      } else {
        strengthLevel = 0;
      }
    } else {
      switch (strength?.strengthweaknesslevels[0]?.strengthweakness_leve) {
        case 'Very Weak':
          strengthLevel = 0;
          break;
        case 'Weak':
          strengthLevel = 1;
          break;
        case 'Average':
          strengthLevel = 2;
          break;
        case 'Strong':
          strengthLevel = 3;
          break;
        case 'Very Strong':
          strengthLevel = 4;
          break;
        default:
          strengthLevel = 0;
          break;
      }
    }

    return strengthLevel;
  };
  const generateDataList = (dataList) => {
    let dataSet = [];
    dataList?.forEach((dataOption) => {
      let dataObject = {
        label: dataOption?.competitor_name,
        data: [],
        borderWidth: 1,
      };
      let colorObject = getRandomColor();
      dataObject = { ...dataObject, ...colorObject };
      if (dataOption?.sub_category?.strengthWeaknesses?.length > 2) {
        dataOption?.sub_category?.strengthWeaknesses?.forEach(
          (strengthOption) => {
            let strengthLevel = getStrengthLevel(strengthOption);
            dataObject.data.push(strengthLevel);
          }
        );
      } else {
        dataObject.data = [0, 0, 0];
      }
      dataSet.push(dataObject);
    });
    return dataSet;
  };
  useEffect(() => {
    if (competitorsDetail && competitorsDetail?.length) {
      let strengthNamesList = getStrengthNames(
        competitorsDetail[0]?.sub_category?.strengthWeaknesses
      );
      let competitorDataSet = generateDataList(competitorsDetail);
      let chartData = {
        labels: strengthNamesList,
        datasets: competitorDataSet,
      };
      setChartData(chartData);
    } else {
      let chartData = {
        labels: ['', '', ''],
        datasets: [],
      };
      setChartData(chartData);
    }
  }, [competitorsDetail]);
  return (
    <Box className="backgroundWhite py1 mt1 borderRadius4">
      {chartData && (
        <Box>
          <Box component={'div'} className="minHeight500">
            <Radar data={chartData} options={options}></Radar>
          </Box>
        </Box>
      )}

      {competitorsDetail &&
        competitorsDetail?.length > 0 &&
        chartData &&
        !chartData?.labels[0] && (
          <Typography component={'p'} className="textAlignCenter">
            {labels.research.ideaTab.compPanel.chart.chartText}
          </Typography>
        )}
      {competitorsDetail?.length < 1 && (
        <Typography component={'p'} className="textAlignCenter">
          {labels.research.ideaTab.compPanel.chart.noCompText}
        </Typography>
      )}
    </Box>
  );
};

export default ChartPanel;
