import { Box, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './footer.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import '../../../Styles/commonStyles.css';

const Footer = () => {
  const saveStatus = useSelector((state) => state.saveStatus.status);

  return (
    <Box className={styles.footerContainer}>
      <Box className="displayFlex backgroundGrey borderRadius4 padding2x10">
        {saveStatus === 'Saving' ? (
          <CircularProgress size={15} />
        ) : saveStatus === "Couldn't save" ? (
          <CancelOutlinedIcon fontSize='small' sx={{ color: 'red' }} />
        ) : (
          <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green' }} />
        )}
        <Typography className='fontSize14 colorBlack'>
          {saveStatus}
        </Typography>
      </Box>
      <Box>
        <Typography className='fontSize14 colorBlack'>Copyright 2024, Pathways</Typography>
      </Box>
      <Box>
        <Typography className='fontSize14 colorBlack'>v1.1.1</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
