import { Box, Button } from '@mui/material';
import { labels } from '../../../../data/constants';
import SubCategoryPanel from './SubCategoryPanel';
import { useState } from 'react';
import SubCategoryModal from './SubCategoryModal';
const MarketSubCategoriesPanel = ({
  marketSubCategories,
  handleSubmit = () => {},
  fetchSubCategories = () => {},
}) => {
  const submitHandler = async (subCategoryName, isNewSubCategory) => {
    const response = await handleSubmit(subCategoryName, isNewSubCategory);
    if (response) {
      setShowModal(false);
    }
  };
  const [showModal, setShowModal] = useState(false);
  return (
    <Box>
      {marketSubCategories?.length !== 0 &&
        marketSubCategories?.map((marketSubCategory, index) => (
          <SubCategoryPanel
            key={'subCategoryPanel' + index}
            marketSubCategoryOption={marketSubCategory}
            fetchSubCategories={fetchSubCategories}
          />
        ))}
      <Button
        className="mt1 mb2 textTrasformCapital"
        variant="buttonVariant1"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {labels.research.marketTab.addSubCategoryLabel}
      </Button>
      {showModal && (
        <SubCategoryModal
          handleCancel={() => {
            setShowModal(false);
          }}
          submitDetails={submitHandler}
        />
      )}
    </Box>
  );
};
export default MarketSubCategoriesPanel;
