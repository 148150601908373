import { Box, Divider, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSharedIdeaDetailService } from '../../Services/ideaDetailService';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import IdeaShareSkeleton from '../../Components/IdeaShare/IdeaShareSkeleton';
import { labels } from '../../data/constants';
const IdeaShareContainer = () => {
  const { id } = useParams();
  const [ideaDetails, setIdeaDetails] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    getIdeaDetails();
  }, []);
  const getIdeaDetails = async () => {
    try {
      let payload = {
        ideaId: id,
      };
      const response = await getSharedIdeaDetailService(payload);
      if (response) {
        setIdeaDetails(response);
        setShowSkeleton(false);
      } else {
        setShowSkeleton(false);
      }
    } catch (e) {
      console.log(e);
      setShowSkeleton(false);
    }
  };
  return (
    <>
      {!showSkeleton && (
        <>
          {ideaDetails ? (
            <Box>
              <Typography
                className="mt2 ml2"
                component={'h1'}
                variant="dashboardCompHeading"
              >
                {ideaDetails?.name}
              </Typography>
              <Typography className="mt2 ml1 mr1" component={'h1'}>
                {ideaDetails?.description}
              </Typography>

              {ideaDetails?.tabs &&
                ideaDetails?.tabs?.length !== 0 &&
                ideaDetails?.tabs
                  .sort((a, b) => a?.sortby - b?.sortby)
                  ?.map((tabItem, index) => (
                    <Box className="mt2 ml1 mr1" key={'tabItem' + index}>
                      <Divider sx={{ marginTop: 1 }} />
                      <Typography
                        component={'h3'}
                        variant="dashboardCompHeading"
                        className="fontSize26 mt1"
                      >
                        {tabItem?.tabName}
                      </Typography>
                      {tabItem?.questions
                        .sort((a, b) => a?.sortby - b?.sortby)
                        ?.map((question, index) =>
                          question?.answer && question?.answer !== ' ' ? (
                            <Box
                              className="mt1"
                              key={tabItem?.tabName + 'questionBox' + index}
                            >
                              <Typography variant="dashboardCompHeading5">
                                {question?.question}
                              </Typography>
                              <Typography className="mt05">
                                {question?.answer}
                              </Typography>
                            </Box>
                          ) : (
                            ''
                          )
                        )}
                    </Box>
                  ))}
              <Box className="displayFlex alignItemsStart mt1_5 ml1 mr2 mb1 tipBackgroundSuccess tipColorSuccess borderRadius4 padding12x16">
                <TipsAndUpdatesIcon fontSize="small" />
                <Box className="ml1">
                  <Typography className="fontSize14 fontWeight700 mb1">
                    {labels.ideaDetails.sharePage.learnMoreLabel}
                  </Typography>
                  <Typography className="colorBlack fontSize14">
                    {labels.ideaDetails.sharePage.learnMorePara}
                    <Link>www.pathways.com</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                className="mt2 textAlignCenter"
                component={'h1'}
                variant="dashboardCompHeading"
              >
                {labels.ideaDetails.sharePage.noIdeaText}
              </Typography>
            </Box>
          )}
        </>
      )}

      {showSkeleton && (
        <Box className="ml1 mr1">
          <IdeaShareSkeleton skeletonCount={10} />
        </Box>
      )}
    </>
  );
};

export default IdeaShareContainer;
