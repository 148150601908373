import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import BusinessIcon from '@mui/icons-material/Business';
import LayersIcon from '@mui/icons-material/Layers';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import PublicIcon from '@mui/icons-material/Public';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CheckIcon from '@mui/icons-material/Check';
import TuneIcon from '@mui/icons-material/Tune';
const ActiveResearchPanel = ({ handleClose, handleOpen, researchStatus }) => {
  console.log(researchStatus);
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      hideBackdrop={true}
      onClose={handleClose}
      open={handleOpen}
      className="customAlignment"
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <Box>
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.marketResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.marketResearchStatus ? (
                    <LanguageIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Market
                  </Typography>
                  <Typography component={'p'} variant="dashboardCompHeading6">
                    ~1 min
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: 20, width: 19 }} orientation="vertical" />
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.subCatResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.subCatResearchStatus ? (
                    <FilterCenterFocusIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Sub-Category
                  </Typography>
                  <Typography component={'p'} variant="dashboardCompHeading6">
                    ~1 min
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: 20, width: 19 }} orientation="vertical" />
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.competitorResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.competitorResearchStatus ? (
                    <BusinessIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Competitor Names
                  </Typography>
                  <Typography component={'p'} variant="dashboardCompHeading6">
                    ~4 min
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: 20, width: 19 }} orientation="vertical" />
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.compSubCatResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.compSubCatResearchStatus ? (
                    <LayersIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Comp. Sub-Cat Profiles
                  </Typography>
                  <Typography component={'p'} variant="dashboardCompHeading6">
                    ~5 min
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: 20, width: 19 }} orientation="vertical" />
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.compStatsResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.compStatsResearchStatus ? (
                    <FormatListBulletedIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Competitor Stats
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: 20, width: 19 }} orientation="vertical" />
              <Box className="displayFlex alignItemsCenter">
                <Avatar
                  className={
                    !researchStatus?.taskStatuses?.compAssessResearchStatus
                      ? 'backgroundGrey borderColorBlue border3'
                      : 'backgroundBlue'
                  }
                >
                  {!researchStatus?.taskStatuses?.compAssessResearchStatus ? (
                    <GridViewIcon fontSize="small" />
                  ) : (
                    <CheckIcon fontSize="small" />
                  )}
                </Avatar>
                <Box className="ml05">
                  <Typography variant="dashboardCompHeading5">
                    Competitive Assessments
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box className="backgroundGradient padding20 borderRadius8 mb1_5">
              <Grid container columnSpacing={2}>
                <Grid item xs={researchStatus?.competitorEvaluated ? 3 : 6}>
                  <Box className="backgroundWhite colorPrimary padding12x24 borderRadius8 textAlignCenter">
                    <PublicIcon fontSize="large" />
                    <Typography component={'p'} variant="dashboardCompHeading7">
                      {researchStatus?.webpageSearched
                        ? researchStatus?.webpageSearched
                        : 0}
                    </Typography>
                    <Typography component={'p'} variant="dashboardCompHeading8">
                      Web Searches
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={researchStatus?.competitorEvaluated ? 3 : 6}>
                  <Box className="backgroundWhite colorPrimary padding12x24 borderRadius8 textAlignCenter">
                    <FindInPageIcon fontSize="large" />
                    <Typography component={'p'} variant="dashboardCompHeading7">
                      {researchStatus?.pageAnalysed
                        ? researchStatus?.pageAnalysed
                        : 0}
                    </Typography>
                    <Typography component={'p'} variant="dashboardCompHeading8">
                      Pages Analyzed
                    </Typography>
                  </Box>
                </Grid>
                {researchStatus?.competitorEvaluated && (
                  <Grid item xs={3}>
                    <Box className="backgroundWhite colorPrimary padding12x24 borderRadius8 textAlignCenter">
                      <BusinessIcon fontSize="large" />
                      <Typography
                        component={'p'}
                        variant="dashboardCompHeading7"
                      >
                        {researchStatus?.competitorEvaluated
                          ? researchStatus?.competitorEvaluated
                          : 0}
                      </Typography>
                      <Typography
                        component={'p'}
                        variant="dashboardCompHeading8"
                      >
                        Competitors Evaluated
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {researchStatus?.attributeConsidered && (
                  <Grid item xs={3}>
                    <Box className="backgroundWhite colorPrimary padding12x24 borderRadius8 textAlignCenter">
                      <TuneIcon fontSize="large" />
                      <Typography
                        component={'p'}
                        variant="dashboardCompHeading7"
                      >
                        {researchStatus?.attributeConsidered
                          ? researchStatus?.attributeConsidered
                          : 0}
                      </Typography>
                      <Typography
                        component={'p'}
                        variant="dashboardCompHeading8"
                      >
                        Attributes Considered
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Typography
              component={'p'}
              className="fontWeight400 displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Generate potential market names
            </Typography>
            <Typography
              component={'p'}
              className="fontWeight400  displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Search the web for validation resources
            </Typography>
            <Typography
              component={'p'}
              className="fontWeight400  displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Select market name and prioritize sources
            </Typography>
            <Typography
              component={'p'}
              className="fontWeight400  displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Fetch and parse data
            </Typography>
            <Typography
              component={'p'}
              className="fontWeight400  displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Analyze data to determine the market details
            </Typography>
            <Typography
              component={'p'}
              className="fontWeight400 displayFlex alignItemsCenter"
              variant="dashboardCompHeading5"
            >
              {!researchStatus?.status ? (
                <CircularProgress className="mr05" size={'1rem'} />
              ) : (
                <CheckIcon color="success" />
              )}
              Process and store results
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ActiveResearchPanel;
