import {
  Box,
  Grid,
  InputLabel,
  Tabs,
  Tab,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { errors, labels } from '../../data/constants';
import { useEffect, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import IdeaTabContent from '../../Components/IdeaDetails/IdeaTabContent';
import {
  aiAutofillService,
  getIdeaDetailsService,
  saveIdeaDetailsService,
  saveIdeaQuestionsService,
} from '../../Services/ideaDetailService';
import IdeaDetailHeader from '../../Components/IdeaDetails/IdeaDetailHeader';
import IdeaDetailsSkeleton from '../../Components/IdeaDetails/IdeaDetailsSkeleton';
import ProfileTabSkeleton from '../../Components/Profile/ProfileTabSkeleton';
import { ideaShareUpdateService } from '../../Services/ideaDetailService';
import { useSelector, useDispatch } from 'react-redux';
import { logChanges } from '../../Services/commentService';
import { saved, saving, couldnotSave } from '../../redux/saveSlice';
import ProRequiredModal from '../../Components/CommonComponents/proRequiredModal/proRequiredModal';
import { proModalsContent } from '../../data/constants';

const IdeaDetailsComp = () => {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const dispatch = useDispatch();

  const [showSkeleton, setShowSkeleton] = useState(true);

  const { id } = useParams();
  const [tabData, setTabData] = useState([]);
  const [tabDataCopy, setTabDataCopy] = useState([]);
  const [ideaData, setIdeaData] = useState({});
  const [ideaDataCopy, setIdeaDataCopy] = useState({});
  const [ideaNameError, setIdeaNameError] = useState({
    status: false,
    message: ' ',
  });
  const [ideaSummaryError, setIdeaSummaryError] = useState({
    status: false,
    message: ' ',
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [proRequiredModalShow, setProRequiredModalShow] = useState(false);
  const isProAccount = useSelector((state) => state?.loginUserData?.user?.userDetails?.proAccountDetails?.isProAccount);

  const handleTabChange = (event, selectedTabValue) => {
    setSelectedTab(selectedTabValue);
  };
  const loadIconAsPerTab = (tabName) => {
    switch (tabName) {
      case labels.ideaDetails.tabs.general.tabLabel:
        return <DescriptionIcon />;
      case labels.ideaDetails.tabs.customers.tabLabel:
        return <SupervisorAccountIcon />;
      case labels.ideaDetails.tabs.goToMarket.tabLabel:
        return <AddBusinessIcon />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userWorkspace) {
      getIdeaDetails();
    }
  }, [userWorkspace]);
  const getIdeaDetails = async () => {
    try {
      let payload = {
        ideaId: id,
        workspaceId: userWorkspace?.id,
      };
      const response = await getIdeaDetailsService(payload);
      if (response) {
        setIdeaData(response?.userIdeas);
        setIdeaDataCopy(response?.userIdeas);
        let tabData = [...response?.alltabsQuestionsAnswers];
        tabData.forEach((tabItem) => {
          tabItem?.questions?.forEach((questionItem) => {
            if (questionItem?.type === 'multiSelect' && questionItem?.answer) {
              let answerArray = questionItem?.answer.split(',');
              let answerObjectArray = [];
              answerArray.forEach((answer) => {
                if (answer !== ' ') {
                  let answerLabel;
                  questionItem?.options?.forEach((option) => {
                    if (option?.value === answer) {
                      answerLabel = option?.label;
                    }
                  });
                  answerObjectArray.push({
                    label: answerLabel,
                    value: answer,
                  });
                }
              });
              questionItem.answer = answerObjectArray;
            } else if (questionItem?.type === 'select') {
              let answer = questionItem?.answer;
              let answerLabel;
              questionItem?.options?.forEach((option) => {
                if (option?.value === answer) {
                  answerLabel = option?.label;
                }
              });
              questionItem.answer = {
                label: answerLabel,
                value: answer,
              };
            } else {
              let answer = questionItem.answer?.trim() || '';
              questionItem.answer = answer;
            }
          });
        });
        let stringifiedData = JSON.stringify(tabData);
        setTabData(
          JSON.parse(stringifiedData).sort((a, b) => a?.sortby - b?.sortby)
        );
        setTabDataCopy(
          JSON.parse(stringifiedData).sort((a, b) => a?.sortby - b?.sortby)
        );
        setShowSkeleton(false);
      } else {
        setShowSkeleton(false);
      }
    } catch (e) {
      console.log(e);
      setShowSkeleton(false);
    }
  };
  const saveIdeaDetails = async () => {
    try {
      let payload = {};
      payload['ideaId'] = ideaDataCopy.id;
      if (ideaNameError.status !== true) {
        payload['name'] = ideaDataCopy.name;
      } else {
        payload['name'] = ideaData.name;
      }
      if (ideaSummaryError !== true) {
        payload['summary'] = ideaDataCopy.description;
      } else {
        payload['name'] = ideaData.description;
      }
      dispatch(saving());
      const response = await saveIdeaDetailsService(payload, dispatch);
      if (response) {
        dispatch(saved());
        getIdeaDetails();
        setIdeaNameError({ status: false, message: ' ' });
        setIdeaSummaryError({ status: false, message: ' ' });
        let changeLogPayload = {
          changeDetail: `Idea '${payload.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const validateIdeaDetails = (fieldName) => {
    switch (fieldName) {
      case 'name':
        if (ideaDataCopy.name === ideaData.name) {
          return;
        }
        if (ideaDataCopy.name === '') {
          setIdeaNameError({
            status: true,
            message: errors.ideaDetails.ideaNameBlank,
          });
          return;
        }
        saveIdeaDetails();
        break;
      case 'summary':
        if (ideaDataCopy.description === ideaData.description) {
          return;
        }

        if (ideaDataCopy.description === '') {
          setIdeaSummaryError({
            status: true,
            message: errors.ideaDetails.ideaSummaryBlank,
          });
          return;
        }
        saveIdeaDetails();
        break;
      default:
        break;
    }
  };
  const handleDataChange = (questionId, answerReceived) => {
    let tabData = [...tabDataCopy];
    tabData.forEach((tabItem) => {
      tabItem.questions.forEach((questionItem) => {
        if (questionItem.id === questionId) {
          questionItem.answer = answerReceived;
        }
      });
    });
    setTabDataCopy([...tabData]);
  };
  const checkAnswerChange = (questionId, answer) => {
    let isAnswerChanged;
    //looping through each tab.
    tabData.forEach((tabItem) => {
      tabItem.questions.forEach((questionItem) => {
        if (questionItem.id === questionId) {
          if (Array.isArray(answer)) {
            //Stringifying it to compare
            if (
              JSON.stringify(questionItem.answer) === JSON.stringify(answer)
            ) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          } else {
            if (questionItem.answer === answer) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          }
        }
      });
    });
    return isAnswerChanged;
  };
  const submitQuestionAnswer = async (questionIdProvided, AnswerProvided) => {
    let isAnswerChanged = checkAnswerChange(questionIdProvided, AnswerProvided);
    if (isAnswerChanged !== true) {
      return;
    }
    let answer = '';
    let answerList = [];
    if (Array.isArray(AnswerProvided)) {
      AnswerProvided.forEach((arrayItem) => {
        answerList.push(arrayItem.value);
      });
      answer = answerList.toString();
    } else if (typeof AnswerProvided === 'object') {
      answer = AnswerProvided.value;
    } else {
      answer = AnswerProvided;
    }

    let payload = {
      questionId: questionIdProvided,
      answer: answer || ' ',
      ideaId: ideaData.id,
      workspaceId: userWorkspace?.id,
    };
    dispatch(saving());
    const response = await saveIdeaQuestionsService(payload, dispatch);
    if (response) {
      dispatch(saved());
      let changeLogPayload = {
        changeDetail: `Idea '${ideaData.name}' updated`,
        workspaceId: userWorkspace.id,
      };
      await logChanges(changeLogPayload, dispatch);
      getIdeaDetails();
    }
  };
  const initiateAiAutoFill = async (questionId, isPro, question) => {
    try {
      if (!isProAccount) {
        setProRequiredModalShow(true);
        return;
      }
      let payload = {
        ideaId: +id,
        autofill_field: question,
        isPro: isPro,
      };
      dispatch(saving());
      const response = await aiAutofillService(payload, dispatch);
      if (response) {
        dispatch(saved());
        handleDataChange(questionId, response);
        submitQuestionAnswer(questionId, response);

        let changeLogPayload = {
          changeDetail: `Idea '${ideaData.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return response;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateIdeaShare = async (ideaId, shareStatus, shareLevel) => {
    try {
      let payload = {
        ideaId: ideaId,
        share: shareStatus,
        ideadetail: shareLevel,
      };
      dispatch(saving());
      const response = await ideaShareUpdateService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setIdeaData((prevState) => {
          return {
            ...prevState,
            ideadetail: shareLevel,
            share: shareStatus,
          };
        });
        setIdeaDataCopy((prevState) => {
          return {
            ...prevState,
            ideadetail: shareLevel,
            share: shareStatus,
          };
        });
        let changeLogPayload = {
          changeDetail: `Idea '${ideaData.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  return (
    <>
      <ProRequiredModal open={proRequiredModalShow} setOpen={setProRequiredModalShow} content={proModalsContent.createWorkspace} />
      <Box className="ml1 mt1 mr1 pb2">
        <IdeaDetailHeader ideaDetail={ideaData} updateShare={updateIdeaShare} />
        {showSkeleton ? (
          <IdeaDetailsSkeleton />
        ) : (
          <Grid container className="mt1">
            <Grid item xs={12} lg={6}>
              <InputLabel className="dashboardLabel1">
                <Typography>{labels.ideaDetails.nameFieldLabel}</Typography>
              </InputLabel>
              <TextField
                value={ideaDataCopy?.name}
                onChange={(e) => {
                  setIdeaNameError({
                    status: false,
                    message: ' ',
                  });
                  setIdeaDataCopy((prevState) => {
                    return { ...prevState, name: e.target.value };
                  });
                }}
                onBlur={() => {
                  validateIdeaDetails('name');
                }}
                disabled={
                  userWorkspace?.role === 'guest'
                    ? userWorkspace?.workspacePrivelege === 'commenter'
                      ? true
                      : false
                    : false
                }
                fullWidth
                className="textFieldType4"
              />
              <FormHelperText error={ideaNameError.status}>
                {ideaNameError.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="dashboardLabel1">
                <Typography>{labels.ideaDetails.summaryFieldLabel}</Typography>
              </InputLabel>
              <TextField
                value={ideaDataCopy?.description || ''}
                multiline
                minRows={3}
                maxRows={3}
                fullWidth
                onBlur={() => {
                  validateIdeaDetails('summary');
                }}
                className="textFieldType4"
                onChange={(e) => {
                  setIdeaSummaryError({
                    status: false,
                    message: ' ',
                  });
                  setIdeaDataCopy((prevState) => {
                    return { ...prevState, description: e.target.value };
                  });
                }}
                disabled={
                  userWorkspace?.role === 'guest'
                    ? userWorkspace?.workspacePrivelege === 'commenter'
                      ? true
                      : false
                    : false
                }
              />
              <FormHelperText error={ideaSummaryError.status}>
                {ideaSummaryError.message}
              </FormHelperText>
            </Grid>
          </Grid>
        )}

        <Tabs
          TabIndicatorProps={{ className: 'customIndicator1' }}
          className="borderBottom2 borderColorGrey"
          value={selectedTab}
          onChange={handleTabChange}
        >
          {tabData.length !== 0 ? (
            tabData.map((tabOption, index) => (
              <Tab
                key={'tabOption' + index}
                className="dashboardTab1"
                sx={{ maxWidth: 300, textAlign: 'left' }}
                label={tabOption.tabName}
                iconPosition="start"
                disableRipple={true}
                icon={loadIconAsPerTab(tabOption.tabName)}
              />
            ))
          ) : (
            <ProfileTabSkeleton />
          )}
        </Tabs>
        {selectedTab !== undefined && (
          <IdeaTabContent
            handleBlur={submitQuestionAnswer}
            handleChange={handleDataChange}
            tabType={tabDataCopy[selectedTab]}
            tabContent={tabDataCopy[selectedTab]?.questions?.sort(
              (a, b) => a?.sortby - b?.sortby
            )}
            aiAutoFill={initiateAiAutoFill}
            //Checking if user is editor or commentor on profile.
            isGuest={
              userWorkspace?.role === 'guest'
                ? userWorkspace?.workspacePrivelege === 'commenter'
                  ? true
                  : false
                : false
            }
          />
        )}
      </Box>
    </>
  );
};

export default IdeaDetailsComp;
