import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { labels, proPackDetails } from "../../../data/constants";
import { useState } from "react";
import { createWorkspace, listWorkspace } from "../../../Services/WorkspaceService";
import { useDispatch } from "react-redux";
import { setUserWorkspaceList } from "../../../redux/login";
import { logChanges } from "../../../Services/commentService";
import { saving, saved, couldnotSave } from "../../../redux/saveSlice";

const ProRequiredModal = ({ open, setOpen, content }) => {
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const handleClose = () => {
    setInputValue('');
    setOpen(false)
  };

  const handleCreateWorkspace = async () => {
    try {
      const payload = {
        workspaceName: inputValue,
      };
      dispatch(saving());
      const createResp = await createWorkspace(payload, dispatch);
      if (createResp) {
        dispatch(saved());
        handleClose();
        const response = await listWorkspace();
        if (response?.data.length > 0) {
          dispatch(setUserWorkspaceList(response?.data));
        }
        let changeLogPayload = {
          changeDetail: `Workspace '${inputValue}' created`,
          workspaceId: createResp?.id
        }
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box mb={2} width="100%" className="justifySpaceBetween displayFlex m0">
          <Typography variant="dashboardCompHeading2">
            {content.heading}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant={'customStyle3'}>
          {labels.accounts.proLabel}
        </Typography>
        <Typography className="colorBlack pb1">
          {content.noteText1}
        </Typography>
        <Typography className="colorBlack pb1">
          {content.noteText2}
        </Typography>
        <FormControl className="width100 colorBlack">
          <FormLabel className="fontSize14 fontWeightBold">
            {labels.accounts.selectPlanHeading}
          </FormLabel>
          <RadioGroup
            aria-labelledby="pro-plan-option"
            name="pro-plan-radio-select"
          >
            <FormControlLabel
              value={proPackDetails.proAnnualPack.proAnnualName}
              control={<Radio />}
              label={
                <>
                  {proPackDetails.proAnnualPack.proAnnualName} - <span className="lineThrough">{proPackDetails.proAnnualPack.proAnnualOldPrice}</span> {proPackDetails.proAnnualPack.proAnnualPrice}<Typography className="ml1 mt1p" variant="badgeText2">{proPackDetails.proAnnualPack.annualDiscount}</Typography>
                </>
              }
            />
            <FormControlLabel
              value={"detailed"}
              control={<Radio />}
              label={proPackDetails.proUnlimitedPack.proUnlimitedName + ' - ' + proPackDetails.proUnlimitedPack.proUnlimitedPrice}
            />
            <FormControlLabel
              value={"detailed"}
              control={<Radio />}
              label={proPackDetails.pro50Pack.pro50Name + ' - ' + proPackDetails.pro50Pack.pro50Price}
            />
          </RadioGroup>
        </FormControl>
        <Box mt={2}>
          <Button className='textTrasformCapital width100 mb1' variant='purpleBtnLight' >
            {labels.accounts.learnMore}
          </Button>
          <Button variant="buttonVariant1" className="textTrasformCapital width100" onClick={handleCreateWorkspace}>
            {labels.accounts.upgradeNow}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProRequiredModal;
