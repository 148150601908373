import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'Saved',
};

const saveSlice = createSlice({
  name: 'saveStatus',
  initialState,
  reducers: {
    saving: (state) => {
      state.status = 'Saving';
    },
    saved: (state) => {
      state.status = 'Saved';
    },
    couldnotSave: (state) => {
      state.status = "Couldn't save";
    },
    resetStatus: (state) => {
      state.status = 'Saved'; // Reset to initial status
    },
  },
});

export const { saving, saved, couldnotSave, resetStatus } = saveSlice.actions;

export default saveSlice.reducer;
