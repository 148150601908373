import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedIdea: '',
  selectedMarketOption: '',
  assessmentList: '',
  competitorList: '',
  selectedCompetitor: '',
  researchIOngoing: false,
};

const researchSlice = createSlice({
  name: 'researchSlice',
  initialState,
  reducers: {
    setSelectedIdea: (state, action) => {
      state.selectedIdea = action.payload;
    },
    setSelectedMarketOption: (state, action) => {
      state.selectedMarketOption = action.payload;
    },
    setAssessmentListOptions: (state, action) => {
      state.assessmentList = action.payload;
    },
    setCompetitorListOption: (state, action) => {
      state.competitorList = action.payload;
    },
    setSelectedCompetitor: (state, action) => {
      state.selectedCompetitor = action.payload;
    },
    setActiveResearch: (state, action) => {
      state.researchIOngoing = action.payload;
    },
  },
});

export const {
  setSelectedIdea,
  setAssessmentListOptions,
  setSelectedMarketOption,
  setCompetitorListOption,
  setSelectedCompetitor,
  setActiveResearch,
} = researchSlice.actions;

export default researchSlice.reducer;
