import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { labels } from '../../../data/constants';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import CompetitorInfoBlock from './CompetitorInfoBlock';
import {
  deleteCompetitorService,
  getCompetitorDetailsService,
} from '../../../Services/ResearchServices';
import { useSelector, useDispatch } from 'react-redux';
import { saved, saving, couldnotSave } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';
import { setSelectedCompetitor } from '../../../redux/researchSlice';

const CompetitorTabContent = ({
  competitorList = [],
  handleAddCompetitor = () => {},
  updateCompetitorDetails = () => {},
  marketAndSubCategoryList = [],
  assessmentList = [],
  fetchAssessmentsList = () => {},
  fetchCompetitorsList = () => {},
  fetchMarketWithSubCategories = () => {},
}) => {
  const dispatch = useDispatch();
  const selectedCompetitorDetails = useSelector(
    (state) => state?.researchData?.selectedCompetitor
  );
  useEffect(() => {
    if (selectedCompetitorDetails) {
      setActiveCompetitor(
        JSON.parse(JSON.stringify(selectedCompetitorDetails))
      );
    }
  }, [selectedCompetitorDetails]);
  const [showAddCompetitorModal, setShowAddCompetitorModal] = useState(false);
  const [activeCompetitor, setActiveCompetitor] = useState(null);
  const addCompetitor = async (competitorName) => {
    const status = await handleAddCompetitor(competitorName);
    if (status) {
      setShowAddCompetitorModal(false);
    }
  };
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const getTeamSizeValue = (sizeLabel) => {
    switch (sizeLabel) {
      case '1-50':
        return 0;
      case '51-200':
        return 25;
      case '201-1000':
        return 50;
      case '1001-5000':
        return 75;
      case '5000+':
        return 100;
      default:
        return 50;
    }
  };
  const getFundingValue = (sizeLabel) => {
    switch (sizeLabel) {
      case 'Minimal':
        return 0;
      case '$1-10M':
        return 25;
      case '$11-50M':
        return 50;
      case '$51-500M':
        return 75;
      case '$501M+':
        return 100;
      default:
        return 50;
    }
  };
  const fetchCompetitorDetail = async (competitorId) => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorId,
      };

      const response = await getCompetitorDetailsService(payload);
      if (response) {
        let competitorData = {
          competitorName: response?.competitor_name,
          competitorId: response?.id,
          relatedMarketSubCategories: [...response?.sub_category],
          teamSize: getTeamSizeValue(response?.team_size),
          funding: getFundingValue(response?.funding),
          annualRevenue: response?.annual_revenue,
          foundingYear: response?.founding_year,
        };
        setActiveCompetitor(competitorData);
        dispatch(setSelectedCompetitor(competitorData));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const deleteCompetitor = async (receivedValue) => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: receivedValue,
      };
      dispatch(saving());
      const response = await deleteCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setActiveCompetitor(null);
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${activeCompetitor.competitorName}' deleted`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  return (
    <Box>
      <Typography
        component={'h3'}
        variant="dashboardCompHeading"
        className="fontSize26 mt1"
      >
        {labels.research.competitorTab.heading}
      </Typography>
      {competitorList?.length && competitorList?.length !== 0 ? (
        <Grid container className="mt1">
          <Grid item lg={2}>
            <Stack
              className="backgroundLightGrey borderRadius8 padding12x16"
              spacing={1}
              divider={<Divider />}
            >
              {competitorList?.map((competitorOption, index) => (
                <Button
                  key={'competitorOption' + index}
                  className=""
                  variant="stackButton"
                  fullWidth
                  onClick={() => {
                    fetchCompetitorDetail(competitorOption?.competitorId);
                  }}
                >
                  {competitorOption?.competitorName}
                </Button>
              ))}
            </Stack>
            <Button
              className="mt1 textTrasformCapital"
              variant="buttonVariant1"
              onClick={() => {
                setShowAddCompetitorModal(true);
              }}
            >
              {labels.research.competitorTab.addCompetitorBtnLabel}
            </Button>
          </Grid>
          <Grid item lg={10}>
            {activeCompetitor ? (
              <CompetitorInfoBlock
                mwscData={marketAndSubCategoryList}
                competitorData={activeCompetitor}
                fetchCompetitorDetail={fetchCompetitorDetail}
                assessmentList={assessmentList}
                fetchAssessmentsList={fetchAssessmentsList}
                deleteCompetitor={deleteCompetitor}
                fetchMarketWithSubCategories={fetchMarketWithSubCategories}
                fetchCompetitorsList={fetchCompetitorsList}
              />
            ) : (
              <Typography
                component={'h2'}
                fontSize={24}
                fontWeight={'bold'}
                className="ml2"
              >
                Please select a competitor.
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box className="textAlignCenter">
          <Typography
            component={'h2'}
            mt={5}
            fontSize={24}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            {labels.research.competitorTab.noCompetitorMsg}
          </Typography>
          <Button
            className="mt1"
            variant="contained"
            onClick={() => {
              setShowAddCompetitorModal(true);
            }}
          >
            {labels.research.competitorTab.addCompetitorBtnLabel}
          </Button>
        </Box>
      )}
      {showAddCompetitorModal && (
        <AddMarketOrCompetitorModal
          dialogTitle={'Add Competitor'}
          toAdd={'Competitor'}
          handleClose={() => {
            setShowAddCompetitorModal(false);
          }}
          submitMarketDetail={addCompetitor}
        />
      )}
    </Box>
  );
};
export default CompetitorTabContent;
