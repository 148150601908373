import { Box, Button, IconButton, TextField, Typography, InputLabel } from '@mui/material';
import { labels } from '../../data/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saved, saving } from '../../redux/saveSlice';
import { addReply, listComments, editComment, deleteComment } from '../../Services/commentService';
import CommentDeleteModal from '../Comments/CommentDeleteModal';
import { Link } from 'react-router-dom';

const CommentsHistory = ({ commentsHistory }) => {
  const [replyTexts, setReplyTexts] = useState({});
  const [fetchedComments, setFetchedComments] = useState(commentsHistory);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const userWorkspace = useSelector((state) => state?.loginUserData?.user?.userDetails?.workspace);
  const dispatch = useDispatch();

  useEffect(() => {
    setFetchedComments(commentsHistory);
  }, [commentsHistory]);

  const handleReplyChange = (commentId, value) => {
    setReplyTexts((prev) => ({
      ...prev,
      [commentId]: value,
    }));
  };

  const handleReplySubmit = async (commentId) => {
    if (commentId && replyTexts[commentId]) {
      const payload = {
        workspaceId: userWorkspace.id,
        commentid: commentId,
        reply: replyTexts[commentId],
      };
      dispatch(saving());
      const response = await addReply(payload, dispatch);
      if (response) {
        dispatch(saved());
        const tempPayload = {
          workspaceId: userWorkspace.id,
        };
        const tempResponse = await listComments(tempPayload, dispatch);
        setFetchedComments(tempResponse);
      }
    }
    setReplyTexts((prev) => ({
      ...prev,
      [commentId]: '',
    }));
  };

  const handleEditComment = (commentId, text) => {
    setEditingCommentId(commentId);
    setEditedCommentText(text);
  };

  const handleEditReply = (replyId, text) => {
    setEditingReplyId(replyId);
    setReplyTexts((prev) => ({
      ...prev,
      [replyId]: text,
    }));
  };

  const handleSave = async (id, isReply) => {
    const commentText = isReply ? replyTexts[id] : editedCommentText;

    const trimmedText = commentText.trim();

    if (trimmedText) {
      const payload = {
        workspaceId: userWorkspace.id,
        commentId: id,
        comment: trimmedText,
      };

      dispatch(saving());
      const response = await editComment(payload, dispatch);
      if (response) {
        dispatch(saved());
        const tempPayload = {
          workspaceId: userWorkspace.id,
        };
        const tempResponse = await listComments(tempPayload, dispatch);
        setFetchedComments(tempResponse);
      }

      if (isReply) {
        setEditingReplyId(null);
        setReplyTexts((prev) => ({ ...prev, [id]: '' }));
      } else {
        setEditingCommentId(null);
        setEditedCommentText('');
      }
    }
  };

  const handleDeleteComment = (commentId, isReply) => {
    setSelectedItemId(commentId);
    setIsReply(isReply);
    setShowDeleteModal(true);
  };

  const confirmDeleteComment = async () => {
    if (selectedItemId) {
      const payload = {
        workspaceId: userWorkspace.id,
        commentId: selectedItemId,
      };
      dispatch(saving());
      const response = await deleteComment(payload, dispatch);
      if (response) {
        dispatch(saved());
        const tempPayload = {
          workspaceId: userWorkspace.id,
        };
        const tempResponse = await listComments(tempPayload, dispatch);
        setFetchedComments(tempResponse);
      }
      setShowDeleteModal(false);
    }
  };

  const formatDate = (date) => {
    const now = new Date();
    const commentDate = new Date(date);
    const options = { hour: '2-digit', minute: '2-digit' };

    if (
      commentDate.getDate() === now.getDate() &&
      commentDate.getMonth() === now.getMonth() &&
      commentDate.getFullYear() === now.getFullYear()
    ) {
      return commentDate.toLocaleTimeString([], options);
    }

    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    if (
      commentDate.getDate() === yesterday.getDate() &&
      commentDate.getMonth() === yesterday.getMonth() &&
      commentDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Yesterday, ${commentDate.toLocaleTimeString([], options)}`;
    }

    return commentDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) +
      `, ${commentDate.toLocaleTimeString([], options)}`;
  };

  return (
    <Box className='mt2'>
      {fetchedComments.map((comment) => {
        const hasReplies = comment.replies.length > 0;
        return (
          <Box key={comment.id} className='mb2 border1 borderColorGrey borderRadius4 boxShadow'>
            <Box className="displayFlex alignItemsCenter">
              <Typography variant='customStyle4'>
                <Link to={comment.currentpageassociation} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {comment.currentpageassociation?.replace(/^\//, '')}
                </Link>
              </Typography>
            </Box>
            <Box className='padding1'>
              <Box className='twoColumnLayout15x85 justifySpaceBetween'>
                <Box className='avatarCircle'>
                  {comment.user.first_name[0] + comment.user.last_name[0]}
                </Box>
                <Box>
                  <Box className='twoColumnLayout85x15 lineHeight1'>
                    <Box>
                      <InputLabel
                        sx={{ overflow: 'visible' }}
                        shrink={false}
                        className="dashboardLabel1"
                      >
                        <Typography variant="customStyle1">{comment.user.first_name} {comment.user.last_name}</Typography>
                      </InputLabel>
                      <Box className='fontSize12'>
                        {formatDate(comment.updatedAt)}
                      </Box>
                    </Box>
                    <Box className="displayFlex">
                      {editingCommentId === comment.id ? (
                        <Button variant="buttonVariant2" onClick={() => handleSave(comment.id, false)}>Done</Button>
                      ) : (
                        !hasReplies && (
                          <>
                            <IconButton size="small" className="mr10" onClick={() => handleEditComment(comment.id, comment.comment)}>
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleDeleteComment(comment.id, false)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </>
                        )
                      )}
                    </Box>
                  </Box>
                  <Box>
                    {editingCommentId === comment.id ? (
                      <TextField
                        fullWidth
                        className='textFieldType5'
                        value={editedCommentText}
                        onChange={(e) => setEditedCommentText(e.target.value)}
                      />
                    ) : (
                      <Box className='paddingTop10'>{comment.comment}</Box>
                    )}
                  </Box>
                </Box>
              </Box>

              {hasReplies && (
                <Box className='m105'>
                  {comment.replies.map((reply) => (
                    <Box key={reply.id} className='padding1'>
                      <Box className='twoColumnLayout15x85 justifySpaceBetween'>
                        <Box className='avatarCircle'>
                          {reply.user.first_name[0] + reply.user.last_name[0]}
                        </Box>
                        <Box>
                          <Box className='twoColumnLayout85x15 lineHeight1'>
                            <Box>
                              <Box>
                                <InputLabel
                                  sx={{ overflow: 'visible' }}
                                  shrink={false}
                                  className="dashboardLabel1"
                                >
                                  <Typography variant="customStyle1">{reply.user.first_name} {reply.user.last_name}</Typography>
                                </InputLabel>
                              </Box>
                              <Box className='fontSize12'>
                                {formatDate(reply.updatedAt)}
                              </Box>
                            </Box>
                            <Box className="displayFlex">
                              {editingReplyId === reply.id ? (
                                <Button variant="buttonVariant2" onClick={() => handleSave(reply.id, true)}>Done</Button>
                              ) : (
                                <>
                                  <IconButton size="small" onClick={() => handleEditReply(reply.id, reply.comment)}>
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton size="small" onClick={() => handleDeleteComment(reply.id, true)}>
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            {editingReplyId === reply.id ? (
                              <TextField
                                fullWidth
                                className='textFieldType5'
                                value={replyTexts[reply.id] || ''}
                                onChange={(e) => handleReplyChange(reply.id, e.target.value)}
                              />
                            ) : (
                              <Box className='paddingTop10'>{reply.comment}</Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              <Box className='m4_5'>
                <TextField
                  fullWidth
                  className='textFieldType5'
                  placeholder={labels.comments.commentsTab.replyPlaceholder}
                  value={replyTexts[comment.id] || ''}
                  onChange={(e) => handleReplyChange(comment.id, e.target.value)}
                />
              </Box>

              {replyTexts[comment.id] && (
                <Box className='twoColumnLayout45x45 m4_5'>
                  <Button
                    className='textTrasformCapital widthFitContent'
                    variant='buttonVariant1'
                    onClick={() => handleReplySubmit(comment.id)}
                  >
                    Reply
                  </Button>
                  <Button
                    className="textAlignCenter textTransformNone"
                    variant="textButton6"
                    onClick={() => handleReplyChange(comment.id, '')}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}

      {showDeleteModal && (
        <CommentDeleteModal
          title={'Delete Comment'}
          message1={isReply ? 'Are you sure you want to delete this reply?' : 'Are you sure you want to delete this comment?'}
          confirmDeleteComment={confirmDeleteComment}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </Box>
  );
}

export default CommentsHistory;
