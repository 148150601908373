import { Typography, Box, Button } from '@mui/material';
import { labels } from '../../../data/constants';
import CustomDialog from '../../Custom Components/CustomDialog/CustomDialog';

const DeleteCompetitorDataModal = ({
  handleClose = () => {},
  handleRemoveFromSubCat = () => {},
  handleDeleteCompletely = () => {},
  isDisabled = false,
}) => {
  return (
    <CustomDialog
      dialogTitle={labels.research.ideaTab.deleteCompDataModal.heading}
      showClose={false}
      width={'xs'}
      handleOpen={true}
      headingVariant="modalHeading1"
    >
      <Typography>
        {labels.research.ideaTab.deleteCompDataModal.subHeading}
      </Typography>

      <Box className="justifySpaceBetween displayFlex mt1_5">
        <Button
          disabled={isDisabled}
          className="ml0 padding12x24"
          variant="textButton3"
          onClick={handleClose}
        >
          {labels.commonLabels.cancelLabel}
        </Button>
        <Box className="displayFlex flexDirectionColumn">
          <Button
            disabled={isDisabled}
            variant="purpleBtnDark"
            onClick={handleRemoveFromSubCat}
            className="textTrasformCapital"
          >
            {labels.research.ideaTab.deleteCompDataModal.removeBtn1}
          </Button>
          <Button
            disabled={isDisabled}
            variant="purpleBtnLight"
            onClick={handleDeleteCompletely}
            className="mt1 textTrasformCapital"
          >
            {labels.research.ideaTab.deleteCompDataModal.removeBtn2}
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default DeleteCompetitorDataModal;
