import {
  Box,
  Typography,
  IconButton,
  InputLabel,
  Slider,
  TextField,
  InputAdornment,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Chip,
  Stack,
  FormHelperText,
} from '@mui/material';
import { teamSizeMarks, fundingMarks, labels } from '../../../data/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import StrengthAndWeaknessPanel from '../StrengthAndWeaknessPanel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  createStrengthWeaknessService,
  updateStrengthAndWeaknessService,
  deleteStrengthWeaknessService,
  updateCompetitorService,
  saveCompSubCatQuestionDtlService,
  addAssessmentService,
  getAssessmentListService,
  deleteCompSubCatService,
  deleteCompetitorService,
} from '../../../Services/ResearchServices';
import { useDispatch } from 'react-redux';
import AddAssessmentModal from '../AddAssessmentModal';
import { setAssessmentListOptions } from '../../../redux/researchSlice';
import DeleteCompetitorDataModal from './DeleteCompetitorDataModal';
import { logChanges } from '../../../Services/commentService';
import { saved, saving, couldnotSave } from '../../../redux/saveSlice';

const CompetitorsBlock = ({
  competitorDetail,
  subCatId,
  subCatName,
  fetchCompetitorsList,
}) => {
  const dispatch = useDispatch();
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const [competitorDetailOriginal, setCompetitorDetailOriginal] =
    useState(null);
  const [competitorDetailCopy, setCompetitorDetailCopy] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fyError, setFYError] = useState({
    status: '',
    msg: '',
  });
  const assessmentList = useSelector(
    (state) => state?.researchData?.assessmentList
  );

  const [showAssessmentModal, setShowAssessmentModal] = useState(false);

  const getLabelValue = (receivedValue, fieldName) => {
    let labelValue = '';
    if (fieldName === 'teamSize') {
      if (receivedValue === teamSizeMarks[0].label) {
        labelValue = teamSizeMarks[0].value;
      } else if (receivedValue === teamSizeMarks[1].label) {
        labelValue = teamSizeMarks[1].value;
      } else if (receivedValue === teamSizeMarks[2].label) {
        labelValue = teamSizeMarks[2].value;
      } else if (receivedValue === teamSizeMarks[3].label) {
        labelValue = teamSizeMarks[3].value;
      } else if (receivedValue === teamSizeMarks[4].label) {
        labelValue = teamSizeMarks[4].value;
      } else {
        if (receivedValue === null) {
          labelValue = 50;
        } else {
          labelValue = receivedValue;
        }
      }
    }
    if (fieldName === 'funding') {
      if (receivedValue === fundingMarks[0].label) {
        labelValue = fundingMarks[0].value;
      } else if (receivedValue === fundingMarks[1].label) {
        labelValue = fundingMarks[1].value;
      } else if (receivedValue === fundingMarks[2].label) {
        labelValue = fundingMarks[2].value;
      } else if (receivedValue === fundingMarks[3].label) {
        labelValue = fundingMarks[3].value;
      } else if (receivedValue === fundingMarks[4].label) {
        labelValue = fundingMarks[4].value;
      } else {
        if (receivedValue === null) {
          labelValue = 50;
        } else {
          labelValue = receivedValue;
        }
      }
    }
    return labelValue;
  };
  useEffect(() => {
    if (competitorDetail) {
      let data = competitorDetail;
      data.teamSize = getLabelValue(competitorDetail?.team_size, 'teamSize');
      data.funding = getLabelValue(competitorDetail?.funding, 'funding');
      setCompetitorDetailOriginal(JSON.parse(JSON.stringify(data)));
      setCompetitorDetailCopy(JSON.parse(JSON.stringify(data)));
    }
  }, [competitorDetail]);

  const handleAnswerChange = (answerValue, fieldName) => {
    switch (fieldName) {
      case 'foundingYear':
        let date = new Date();
        let currentYear = date.getFullYear();
        if (answerValue > currentYear) {
          setCompetitorDetailCopy((prevState) => {
            return {
              ...prevState,
              founding_year: answerValue,
            };
          });
          setFYError({
            status: true,
            msg: 'Cannot be greater than current year!',
          });
        } else {
          setCompetitorDetailCopy((prevState) => {
            return {
              ...prevState,
              founding_year: answerValue,
            };
          });
        }
        break;
      case 'annualRevenue':
        setCompetitorDetailCopy((prevState) => {
          return {
            ...prevState,
            annual_revenue: answerValue,
          };
        });
        break;
      default:
        break;
    }
  };
  const handleQuestionAnswerChange = (answerReceived, questionId) => {
    let compDtl = competitorDetailCopy;
    compDtl?.sub_category?.questionAnswers?.forEach((questionOption) => {
      if (questionOption?.id === questionId) {
        questionOption.answer = answerReceived;
      }
    });
    setCompetitorDetailCopy(() => {
      return {
        ...compDtl,
      };
    });
  };
  const handleAnswerSubmit = async (receivedValue, field) => {
    try {
      if (competitorDetailOriginal[field] === receivedValue) {
        return;
      }
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetailCopy?.id,
      };
      payload[field] = receivedValue;
      dispatch(saving());
      const response = await updateCompetitorService(payload, dispatch);
      if (!response) {
        dispatch(saved());
        console.log(response);
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const handleQuestionAnswerSubmit = async (answerReceived, questionId) => {
    try {
      let isAnswerChanged = '';
      competitorDetailOriginal?.sub_category?.questionAnswers?.forEach(
        (questionOption) => {
          if (questionOption?.id === questionId) {
            if (questionOption?.answer === answerReceived) {
              isAnswerChanged = false;
            } else {
              isAnswerChanged = true;
            }
          }
        }
      );

      if (!isAnswerChanged) {
        return;
      }

      let payload = {
        questionId: questionId,
        subcategoryId: subCatId,
        competitorId: competitorDetailOriginal?.id,
        marketId: competitorDetailOriginal?.sub_category?.market_id,
        workspaceId: userWorkspace?.id,
        answer: answerReceived || ' ',
      };
      dispatch(saving());
      const response = await saveCompSubCatQuestionDtlService(
        payload,
        dispatch
      );
      if (response) {
        dispatch(saved());
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const getValueLabel = (fieldValue, fieldName) => {
    let valueLabel = '';
    if (fieldName === 'teamSize') {
      switch (fieldValue) {
        case 0:
          valueLabel = teamSizeMarks[0].label;
          break;
        case 25:
          valueLabel = teamSizeMarks[1].label;
          break;
        case 50:
          valueLabel = teamSizeMarks[2].label;
          break;
        case 75:
          valueLabel = teamSizeMarks[3].label;
          break;
        case 100:
          valueLabel = teamSizeMarks[4].label;
          break;
        default:
          break;
      }
    }
    if (fieldName === 'funding') {
      switch (fieldValue) {
        case 0:
          valueLabel = fundingMarks[0].label;
          break;
        case 25:
          valueLabel = fundingMarks[1].label;
          break;
        case 50:
          valueLabel = fundingMarks[2].label;
          break;
        case 75:
          valueLabel = fundingMarks[3].label;
          break;
        case 100:
          valueLabel = fundingMarks[4].label;
          break;
        default:
          break;
      }
    }
    return valueLabel;
  };
  const updateTeamSize = async (receivedValue) => {
    try {
      setCompetitorDetailCopy((prevState) => {
        return {
          ...prevState,
          teamSize: receivedValue,
        };
      });
      let valueLabel = getValueLabel(receivedValue, 'teamSize');
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetailOriginal?.id,
        team_size: valueLabel,
      };
      dispatch(saving());
      const response = await updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setCompetitorDetailOriginal((prevState) => {
          return {
            ...prevState,
            teamSize: receivedValue,
          };
        });
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const updateFunding = async (receivedValue) => {
    try {
      setCompetitorDetailCopy((prevState) => {
        return {
          ...prevState,
          funding: receivedValue,
        };
      });
      let valueLabel = getValueLabel(receivedValue, 'funding');
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetailOriginal?.id,
        funding: valueLabel,
      };
      dispatch(saving());
      const response = updateCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setCompetitorDetailOriginal((prevState) => {
          return {
            ...prevState,
            funding: receivedValue,
          };
        });
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const addStrength = async (strengthName, strengthType) => {
    try {
      let payload = {
        subCategoryId: subCatId,
        name: strengthName,
        type: strengthType,
      };
      const response = await createStrengthWeaknessService(payload, dispatch);
      if (response) {
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Custom Strength/Weakness '${strengthName}' created in '${subCatName}' sub-category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };

  const updateStrength = async (level, swId) => {
    try {
      let payload = {
        strengthWeaknessId: swId,
        level: level,
        competitorId: competitorDetail?.id,
        workspaceId: userWorkspace?.id,
        subcategoryId: subCatId,
      };
      const response = await updateStrengthAndWeaknessService(
        payload,
        dispatch
      );
      if (response) {
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetail?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const deleteStrength = async (strengthId) => {
    try {
      let payload = {
        strengthWeaknessId: strengthId,
      };
      dispatch(saving());
      const response = await deleteStrengthWeaknessService(payload, dispatch);
      if (response) {
        dispatch(saved());
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Custom Strength/Weakness '${response.name}' deleted from '${subCatName}' sub-category`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
        return true;
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const addAssessment = async (recievedValue) => {
    try {
      let payload = {
        name: recievedValue,
      };
      dispatch(saving());
      const response = await addAssessmentService(payload, dispatch);
      if (response) {
        dispatch(saved());
        const listResponse = await getAssessmentListService();
        if (listResponse) {
          dispatch(setAssessmentListOptions(listResponse));
        }
        setShowAssessmentModal(false);
        handleQuestionAnswerSubmit(recievedValue, 5);
        handleQuestionAnswerChange(recievedValue, 5);
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const removeFromSubCat = async () => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.id,
        subcategoryId: subCatId,
        ideaId: selectedIdea?.id,
        marketId: competitorDetailOriginal?.sub_category?.market_id,
      };
      dispatch(saving());
      const response = await deleteCompSubCatService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setShowDeleteModal(false);
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetailOriginal?.competitor_name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
      setShowDeleteModal(false);
    }
  };
  const removeEntirely = async () => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        competitorId: competitorDetail?.id,
      };
      dispatch(saving());
      const response = await deleteCompetitorService(payload, dispatch);
      if (response) {
        dispatch(saved());
        setShowDeleteModal(false);
        fetchCompetitorsList();
        let changeLogPayload = {
          changeDetail: `Competitor '${competitorDetail.competitor_name}' deleted`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
      setShowDeleteModal(false);
    }
  };
  return (
    <Box className="tipBackgroundWarning borderRadius8 padding1">
      <Box className="displayFlex alignItemsCenter">
        <Typography
          component={'h3'}
          variant="dashboardCompHeading"
          className="fontSize22 mr1"
        >
          {competitorDetailCopy?.competitor_name}
        </Typography>
        <IconButton
          onClick={() => {
            setShowDeleteModal(true);
          }}
          aria-label="delete"
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box className="mt1 padding12x24 backgroundLightGrey borderRadius8">
        <InputLabel
          shrink={false}
          className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter"
        >
          <Typography>{labels.research.commonLabels.teamSize}</Typography>
        </InputLabel>
        <Slider
          className="customSlider ml1 width95"
          step={25}
          marks={teamSizeMarks}
          value={
            competitorDetailCopy?.teamSize !== null
              ? competitorDetailCopy?.teamSize
              : 50
          }
          onChange={(e) => {
            updateTeamSize(e.target.value);
          }}
          min={0}
          max={100}
        />
        <InputLabel
          shrink={false}
          className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter"
        >
          <Typography className="mt1">
            {labels.research.commonLabels.funding}
          </Typography>
        </InputLabel>
        <Slider
          className="customSlider ml1 width95"
          step={25}
          marks={fundingMarks}
          value={
            competitorDetailCopy?.funding !== null
              ? competitorDetailCopy?.funding
              : 50
          }
          onChange={(e) => {
            updateFunding(e.target.value);
          }}
          min={0}
          max={100}
        />
        <Grid className="mt05" container columnSpacing={3}>
          <Grid item lg={6}>
            <InputLabel
              shrink={false}
              className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter"
            >
              <Typography variant="customStyle1">
                {labels.research.commonLabels.annualRevenue}
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4 backgroundWhite"
              type="number"
              value={competitorDetailCopy?.annual_revenue || ''}
              onChange={(e) => {
                handleAnswerChange(e.target.value, 'annualRevenue');
              }}
              onBlur={(e) => {
                handleAnswerSubmit(e.target.value, 'annual_revenue');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment className="ml05" position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <InputLabel
              shrink={false}
              className="dashboardLabel1 overflowVisible displayFlex alignItemsCenter"
            >
              <Typography variant="customStyle1">
                {labels.research.commonLabels.foundingYear}
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              className="textFieldType4 backgroundWhite"
              type="number"
              value={competitorDetailCopy?.founding_year || ''}
              onChange={(e) => {
                setFYError({ status: false, msg: '' });
                handleAnswerChange(e.target.value, 'foundingYear');
              }}
              onBlur={(e) => {
                handleAnswerSubmit(e.target.value, 'founding_year');
              }}
            />
            <FormHelperText error={fyError.status}>
              {fyError.msg}
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>
      <Divider className="mt2 mb1" />
      <Typography
        component={'p'}
        className="colorPrimary fontWeight600"
        variant="dashboardCompHeading5"
      >
        Sub-Category Profile
      </Typography>

      <Typography
        component={'p'}
        className="mt1 mb05 colorBlack fontSize14 fontWeight500"
        variant="dashboardCompHeading5"
      >
        Strengths and Weaknesses
      </Typography>

      <StrengthAndWeaknessPanel
        handleAddStrength={addStrength}
        handleStrengthDelete={deleteStrength}
        handleStrengthUpdate={updateStrength}
        SWOptionsArray={competitorDetailCopy?.sub_category?.strengthWeaknesses}
      />
      <Accordion defaultExpanded={true} className="mt1 customAccordion1">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'accordionControl' + competitorDetail?.id}
          id={'accordion' + competitorDetail?.id}
        >
          Details
        </AccordionSummary>
        <AccordionDetails>
          {competitorDetailCopy?.sub_category?.questionAnswers?.map(
            (questionOption, index) =>
              questionOption?.id !== 5 && questionOption?.id !== 6 ? (
                <Box key={'qustionOption' + index}>
                  <InputLabel
                    shrink={false}
                    className="dashboardLabel1 overflowVisible"
                  >
                    <Typography variant="customStyle1">
                      {questionOption?.question}
                    </Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    className="textFieldType4 backgroundWhite"
                    multiline
                    value={questionOption?.answer || ''}
                    onChange={(e) =>
                      handleQuestionAnswerChange(
                        e.target.value,
                        questionOption?.id
                      )
                    }
                    onBlur={(e) =>
                      handleQuestionAnswerSubmit(
                        e.target.value,
                        questionOption?.id
                      )
                    }
                  />
                </Box>
              ) : (
                ''
              )
          )}
        </AccordionDetails>
      </Accordion>
      <Grid className="mt1" container columnSpacing={2}>
        {competitorDetailCopy?.sub_category?.questionAnswers?.map(
          (questionOption) =>
            questionOption?.id === 5 || questionOption?.id === 6 ? (
              <Grid key={'questionOption' + questionOption?.id} item lg={6}>
                <InputLabel
                  sx={{ overflow: 'visible' }}
                  shrink={false}
                  className="dashboardLabel1"
                >
                  <Typography variant="customStyle1">
                    {questionOption?.question}
                  </Typography>
                </InputLabel>
                {questionOption?.id === 5 ? (
                  <Stack direction={'row'} className="mt1 flexWrap" spacing={1}>
                    {assessmentList?.map((assessmentOption, index) => (
                      <Chip
                        key={'assessmentOpt' + index}
                        label={assessmentOption?.competitive_assessment_name}
                        className="backgroundWhite mb05"
                        clickable
                        variant="outlined"
                        icon={
                          questionOption?.answer ===
                          assessmentOption?.competitive_assessment_name ? (
                            <DoneOutlinedIcon fontSize="small" />
                          ) : (
                            ''
                          )
                        }
                        color={
                          questionOption?.answer ===
                          assessmentOption?.competitive_assessment_name
                            ? 'success'
                            : 'default'
                        }
                        onClick={() => {
                          handleQuestionAnswerChange(
                            assessmentOption?.competitive_assessment_name,
                            questionOption?.id
                          );
                          handleQuestionAnswerSubmit(
                            assessmentOption?.competitive_assessment_name,
                            questionOption?.id
                          );
                        }}
                      />
                    ))}
                    <Chip
                      label="Other+"
                      className="backgroundWhite mb05"
                      variant="outlined"
                      clickable
                      onClick={() => {
                        setShowAssessmentModal(true);
                      }}
                    />
                  </Stack>
                ) : (
                  <TextField
                    fullWidth
                    className="textFieldType4 backgroundWhite"
                    multiline
                    value={questionOption?.answer || ''}
                    onChange={(e) =>
                      handleQuestionAnswerChange(
                        e.target.value,
                        questionOption?.id
                      )
                    }
                    onBlur={(e) =>
                      handleQuestionAnswerSubmit(
                        e.target.value,
                        questionOption?.id
                      )
                    }
                  />
                )}
              </Grid>
            ) : (
              ''
            )
        )}
      </Grid>
      {showAssessmentModal && (
        <AddAssessmentModal
          handleCancel={() => setShowAssessmentModal(false)}
          submitDetails={(receivedValue) => {
            addAssessment(receivedValue);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteCompetitorDataModal
          handleClose={() => {
            setShowDeleteModal(false);
          }}
          handleRemoveFromSubCat={removeFromSubCat}
          handleDeleteCompletely={removeEntirely}
        />
      )}
    </Box>
  );
};

export default CompetitorsBlock;
