import { Box, Grid, Button, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import CustomSelect from '../Custom Components/CustomSelect/CustomSelect';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setSelectedIdea,
  setSelectedMarketOption,
} from '../../redux/researchSlice';
import { useSelector } from 'react-redux';
import { getResearchIdeaService } from '../../Services/ResearchServices';
const IdeaSelectorComp = ({
  ideaOptions = [],
  ideaList = [],
  handleIdeaChange = () => {},
  forComp = '',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedIdeaDetails = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  //For details
  const [selectedIdea, setSelectedIdeaOption] = useState(null);
  //For select component
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedIdeaDetails) {
      setSelectedIdeaOption(selectedIdeaDetails);
      setSelectedOption({
        label: selectedIdeaDetails?.name,
        value: selectedIdeaDetails?.id,
      });
    }
  }, [selectedIdeaDetails]);

  const handleIdeaSelectChange = async (value) => {
    if (forComp === 'research') {
      try {
        //Filtering out selected idea details from idea list.
        let idea = ideaList.filter((ideaOpt) => ideaOpt?.id === value?.value);
        let payload = {
          workspaceId: userWorkspace?.id,
          ideaId: value?.value,
        };
        const response = await getResearchIdeaService(payload);
        //If idea details are present in research Idea details then set them in redux.
        if (response?.researchIdeaDetails?.length) {
          let ideaInfo = { ...response?.idea };

          ideaInfo['marketList'] = [...response?.researchIdeaDetails];
          dispatch(setSelectedIdea(ideaInfo));
          dispatch(setSelectedMarketOption(''));
        } else {
          //If not then idea details fetch from ideas list is stored.F
          dispatch(setSelectedIdea(idea[0]));
          dispatch(setSelectedMarketOption(''));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      let idea = ideaList.filter((ideaOpt) => ideaOpt?.id === value?.value);
      setSelectedOption(value);
      setSelectedIdeaOption(idea[0]);
      handleIdeaChange(value?.value);
    }
  };

  return (
    <Grid mt={1} p={2} container className="backgroundLightGrey borderRadius8">
      <Grid item xs={12} lg={6}>
        <Box className="displayFlex alignItemsCenter">
          <Typography className="mr1 mb0" variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.title}
          </Typography>
          <Box className="flex1">
            <CustomSelect
              selectedValue={selectedOption || []}
              handleChange={(value) => {
                handleIdeaSelectChange(value);
              }}
              dropDownItems={ideaOptions}
              multi={false}
              customStyles={{
                marginTop: '0px',
                minHeight: '40px',
                marginRight: '16px',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} className="displayFlex alignItemsCenter">
        {selectedIdea ? (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="paraText1">
              {selectedIdea?.description}
              <Button
                variant="textButton2"
                onClick={() => {
                  navigate(`/businessIdeas/${selectedIdea?.id}`);
                }}
              >
                {labels.businessIdeas.businessIdeasContainer.detailButton}
              </Button>
            </Typography>
          </Box>
        ) : (
          <Typography variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.subTitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default IdeaSelectorComp;
