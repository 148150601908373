import {
  Typography,
  Box,
  Link,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
  Divider,
} from '@mui/material';
import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import { labels } from '../../data/constants';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
const AddMarketOrCompetitorModal = ({
  dialogTitle,
  toAdd,
  handleClose = () => {},
  submitMarketDetail = () => {},
}) => {
  const [fieldValue, setFieldValue] = useState('');
  const [fieldError, setFieldError] = useState({ status: false, message: ' ' });
  const handleSubmit = () => {
    if (fieldValue === '' || fieldValue.trim() === '') {
      setFieldError({
        status: true,
        message:
          toAdd === 'Market'
            ? 'Market name cannot be empty!'
            : 'Competitor name cannot be empty!',
      });
      return;
    }
    submitMarketDetail(fieldValue);
  };
  const handleCancel = () => {
    handleClose();
  };
  return (
    <CustomDialog
      dialogTitle={dialogTitle}
      showClose={false}
      width={'xs'}
      handleOpen={true}
      headingVariant="modalHeading1"
    >
      <Box>
        {/* <Typography className="textAlignCenter" variant="paraText1">
          {
            labels.research.marketTab.marketCompetitorModal
              .userAutoReserachLabel
          }
        </Typography> */}
        <Box component={'div'} className="textAlignCenter">
          <Typography component={'p'} className="textAlignCenter">
            Use
          </Typography>
          <Button
            variant="buttonVariant1"
            startIcon={<EditIcon fontSize="small" />}
            className="displayFlex alignItemsCenter textTrasformCapital mlAuto mrAuto"
          >
            AI Auto-Research
          </Button>
          <Typography component={'p'} className="textAlignCenter mt05">
            on the ideas tab
          </Typography>
        </Box>
        <Divider className="mt05 mb1">
          <Typography variant="paraText1">
            {labels.research.marketTab.marketCompetitorModal.orLabel}
          </Typography>
        </Divider>
        {toAdd === 'Market' && (
          <Box>
            <Typography variant="paraText1">
              {
                labels.research.marketTab.marketCompetitorModal
                  .industryStandardLabel
              }
            </Typography>
            <ul>
              <li>
                <Typography variant="paraText1">
                  <Link href="https://www.census.gov/naics/?58967?yearbck=2022">
                    {labels.research.marketTab.marketCompetitorModal.naicsLabel}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="paraText1">
                  <Link href="https://www.msci.com/documents/1296102/11185224/GICS+Map+2023.xlsx/82cc6504-9919-29e5-9789-a24fc039d0a5?t=1679087572540">
                    {labels.research.marketTab.marketCompetitorModal.gicsLabel}
                  </Link>
                </Typography>
              </li>
              <li>
                <Typography variant="paraText1">
                  {
                    labels.research.marketTab.marketCompetitorModal
                      .techCompanyLabel1
                  }{' '}
                  <Link href="https://www.crunchbase.com/">
                    {
                      labels.research.marketTab.marketCompetitorModal
                        .crunchbaseLabel
                    }
                  </Link>
                  {
                    labels.research.marketTab.marketCompetitorModal
                      .techCompanyLabel2
                  }
                </Typography>
              </li>
            </ul>
          </Box>
        )}
        <Box component={'form'}>
          <InputLabel required className="dashboardLabel1 fontWeight500 mb05">
            {toAdd === 'Market'
              ? labels.research.marketTab.marketCompetitorModal.marketFieldLabel
              : labels.research.marketTab.marketCompetitorModal
                  .competitorFieldLabel}
          </InputLabel>
          <TextField
            fullWidth
            className="textFieldType3"
            value={fieldValue}
            placeholder={
              toAdd === 'Market'
                ? labels.research.marketTab.marketCompetitorModal
                    .marketPlaceholder
                : labels.research.marketTab.marketCompetitorModal
                    .competitorPlaceholder
            }
            onChange={(e) => {
              setFieldError({ status: false, message: ' ' });
              setFieldValue(e.target.value);
            }}
          />
          <FormHelperText className="mt05" error={fieldError.status}>
            {fieldError.message}
          </FormHelperText>
          {toAdd === 'Market' && (
            <Typography className="mt05" variant="paraText1">
              {
                labels.research.marketTab.marketCompetitorModal
                  .marketSuggestText
              }
            </Typography>
          )}
          <Box className="justifySpaceBetween displayFlex mt1_5">
            <Button variant="buttonVariant1" onClick={handleCancel}>
              {labels.commonLabels.cancelLabel}
            </Button>
            <Button variant="buttonVariant1" onClick={handleSubmit}>
              {labels.commonLabels.submitLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default AddMarketOrCompetitorModal;
